<template>
  <div>
    <a-row :gutter="16" v-for="(rule, index) in rules" :key="index">
      <!-- And/Or -->
      <a-col :span="4">
        <a-select v-if="rule.operator.length > 0" v-model:value="rule.operator">
          <a-select-option value="And">並且</a-select-option>
          <a-select-option value="Or">或者</a-select-option>
        </a-select>
      </a-col>

      <!-- 類別 -->
      <a-col :span="4">
        <a-select
          v-model:value="rule.type"
          @change="handleTypeChange(index, rule.type)"
        >
          <a-select-option
            v-for="item in getTypeList(index)"
            :key="item.type"
            :value="item.type"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>

      <!-- 項目 -->
      <a-col :span="getItemSpanNumber(rule.type)" v-if="rule.type !== ''">
        <a-select v-model:value="rule.item" @change="handleItemChange(index)">
          <a-select-option
            v-for="item in getItemList(index).items"
            :key="item.key"
            :value="item.key"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>

      <!-- 運算子 -->
      <a-col :span="4" v-if="rule.item !== '' && rule.type !== 'BasicSearch'">
        <a-select v-model:value="rule.operatorItem">
          <a-select-option value="=">等於</a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'number'"
            value=">"
          >
            大於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'number'"
            value=">="
          >
            大於等於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'number'"
            value="<"
          >
            小於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'number'"
            value="<="
          >
            小於等於
          </a-select-option>
          <a-select-option value="<>">不等於</a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'number'"
            value="between"
          >
            介於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.item, index).valueType == 'string'"
            value="contains"
          >
            包含
          </a-select-option>
        </a-select>
      </a-col>

      <a-col :span="6" v-if="rule.operatorItem !== ''">
        <a-select
          v-if="getValueDetail(rule.item, index).valueType == 'list'"
          v-model:value="rule.operantItem1"
          style="width: 100%"
        >
          <a-select-option
            v-for="item in getValueDetail(rule.item, index).list"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          v-if="
            getValueDetail(rule.item, index).valueType !== 'list' &&
            rule.operatorItem !== 'between' &&
            getValueDetail(rule.item, index).valueType !== 'store'
          "
          v-model:value="rule.operantItem1"
          style="width: 100%"
        />
        <a-input-group compact v-if="rule.operatorItem == 'between'">
          <a-input
            v-model:value="rule.operantItem1"
            style="width: 40%; text-align: center"
            placeholder="Minimum"
          />
          <a-input
            style="
              width: 10%;
              border-left: 0;
              pointer-events: none;
              backgroundcolor: #fff;
            "
            placeholder="~"
            disabled
          />
          <a-input
            v-model:value="rule.operantItem2"
            style="width: 40%; text-align: center; border-left: 0"
            placeholder="Maximum"
          />
        </a-input-group>
        <StoreSelector
          :area_id="rule.operantItem1"
          :area_sub_id="rule.operantItem2"
          :store_id="rule.operantItem3"
          v-if="getValueDetail(rule.item, index).valueType == 'store'"
          @click="getParentIndex(index)"
          @get-value="handleGetStoreValue"
        />
      </a-col>
      <a-col :span="2">
        <CloseOutlined @click="delRule(index)" />
        <PlusSquareOutlined v-if="rules[0].type !== ''" @click="addRule" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import { computed, reactive, ref, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons-vue'
  import StoreSelector from '@/components/StoreSelector'
  export default {
    name: 'settingRule',
    components: {
      CloseOutlined,
      PlusSquareOutlined,
      StoreSelector,
    },
    props: {
      typeString: {
        type: String,
        default: 'basic',
      },
      rules: {
        type: Array,
      },
    },
    setup(props) {
      const store = useStore()
      const rulesChangeIndex = ref(0)
      const rules = reactive(props.rules)

      // 篩選項目架構
      const schema = computed(() => store.getters['searchSetting/schema'])
      onBeforeMount(() => {
        store.dispatch('searchSetting/initSchema', 'basic')
      })

      // 類別清單
      const getTypeList = () => {
        return schema.value
      }

      // 類別更新事件：如果是第一項被更動，就全部條件清除，只留第一項選取的類別
      const handleTypeChange = (index, type) => {
        if (index == 0 && props.typeString == 'basic') {
          store.dispatch('searchSetting/initRules', type)
        } else {
          store.dispatch('searchSetting/clearRuleItem', index)
        }
      }

      // 項目更新事件：該條件運算元與運算子清空
      const handleItemChange = (index) => {
        store.dispatch('searchSetting/clearOperator', index)
      }

      // 取得資料清單
      const getItemList = (index) => {
        return schema.value.find((item) => {
          return item.type === rules[index].type
        })
      }

      // 取得資料類型
      const getValueDetail = (key, index) => {
        const itemList = schema.value.find((item) => {
          return item.type === rules[index].type
        }).items
        return itemList.find((item) => item.key === key)
      }

      // 新增條件
      const addRule = () => {
        store.dispatch('searchSetting/addRule')
      }

      // 刪除條件
      const delRule = (index) => {
        store.dispatch('searchSetting/delRule', index)
      }

      // 取得門市資料
      const handleGetStoreValue = (value) => {
        rules[rulesChangeIndex.value].operantItem1 = value.area_id
        rules[rulesChangeIndex.value].operantItem2 = value.area_sub_id
        rules[rulesChangeIndex.value].operantItem3 = value.store_id
      }

      const getParentIndex = (index) => {
        rulesChangeIndex.value = index
      }

      // 取得項目寬度
      const getItemSpanNumber = (type) => {
        return type === 'BasicSearch' ? '12' : '4'
      }

      return {
        schema,
        getTypeList,
        getItemList,
        getValueDetail,
        handleTypeChange,
        handleItemChange,
        addRule,
        delRule,
        handleGetStoreValue,
        getParentIndex,
        getItemSpanNumber,
      }
    },
  }
</script>

<style></style>
