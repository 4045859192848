import { render } from "./SearchBlack.vue?vue&type=template&id=f4c198a4"
import script from "./SearchBlack.vue?vue&type=script&lang=js"
export * from "./SearchBlack.vue?vue&type=script&lang=js"

import "./SearchBlack.vue?vue&type=style&index=0&id=f4c198a4&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4c198a4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4c198a4', script)) {
    api.reload('f4c198a4', script)
  }
  
  module.hot.accept("./SearchBlack.vue?vue&type=template&id=f4c198a4", () => {
    api.rerender('f4c198a4', render)
  })

}

script.__file = "src/views/setting/SearchBlack.vue"

export default script