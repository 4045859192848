import { render } from "./DataTable.vue?vue&type=template&id=0023c3fb"
import script from "./DataTable.vue?vue&type=script&lang=js"
export * from "./DataTable.vue?vue&type=script&lang=js"

import "./DataTable.vue?vue&type=style&index=0&id=0023c3fb&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0023c3fb"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0023c3fb', script)) {
    api.reload('0023c3fb', script)
  }
  
  module.hot.accept("./DataTable.vue?vue&type=template&id=0023c3fb", () => {
    api.rerender('0023c3fb', render)
  })

}

script.__file = "src/views/setting/DataTable.vue"

export default script