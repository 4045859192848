<template>
  <div class="test-container">
    <a-alert message="动态路由测试"></a-alert>
  </div>
</template>

<script>
  export default {
    name: 'Test',
  }
</script>
