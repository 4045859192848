import { render } from "./_id.vue?vue&type=template&id=43fb49d0"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"

import "./_id.vue?vue&type=style&index=0&id=43fb49d0&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "43fb49d0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('43fb49d0', script)) {
    api.reload('43fb49d0', script)
  }
  
  module.hot.accept("./_id.vue?vue&type=template&id=43fb49d0", () => {
    api.rerender('43fb49d0', render)
  })

}

script.__file = "src/views/setting/searchCaseHealth/_id.vue"

export default script