import { render } from "./index.vue?vue&type=template&id=f702154e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f702154e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f702154e', script)) {
    api.reload('f702154e', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=f702154e", () => {
    api.rerender('f702154e', render)
  })

}

script.__file = "src/views/healthManagement/healthReport/index.vue"

export default script