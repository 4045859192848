<template>
  <div class="accountManagement-container">
    <h3>服務方案管理頁面</h3>
  </div>
</template>

<script>
  export default {
    name: 'accountManagement',
  }
</script>
<style lang="less"></style>
