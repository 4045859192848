<template>
  <div class="searchCaseHealth-container">
    <a-page-header title="個案管理常用搜尋設定">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <SearchBlack :filter="filter" @search="handleSearch"></SearchBlack>
    <a-button type="primary" @click="handleAddCaseHealthSetting">
      新增常用搜尋
    </a-button>
    <DataTable @edit="handleEdit" @delete="handleDelete" />
  </div>
</template>

<script>
  import { reactive, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'

  import SearchBlack from '@/views/setting/SearchBlack'
  import DataTable from './DataTable'
  export default {
    name: 'accountManagement',
    components: {
      QuestionCircleOutlined,
      SearchBlack,
      DataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const filter = reactive({})
      const searchData = computed(() => store.getters['searchSetting/search'])

      const handleSearch = () => {
        store.dispatch('searchSetting/getSettingCaseList', {
          searchString: searchData.value.searchString,
        })
      }

      const handleAddCaseHealthSetting = () => {
        router.push({
          path: `searchSetting/caseHealth/-1`,
        })
      }
      const handleEdit = (id) => {
        router.push({
          path: `searchSetting/caseHealth/${id}`,
        })
      }

      const handleDelete = (id) => {
        store.dispatch('searchSetting/removeSettingCaseList', {
          id,
        })
      }

      return {
        filter,
        handleSearch,
        handleAddCaseHealthSetting,
        handleEdit,
        handleDelete,
      }
    },
  }
</script>
<style lang="less">
  .searchCaseHealth-container {
  }
</style>
