import { render } from "./NoOfPeople.vue?vue&type=template&id=797a9160"
import script from "./NoOfPeople.vue?vue&type=script&lang=js"
export * from "./NoOfPeople.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "797a9160"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('797a9160', script)) {
    api.reload('797a9160', script)
  }
  
  module.hot.accept("./NoOfPeople.vue?vue&type=template&id=797a9160", () => {
    api.rerender('797a9160', render)
  })

}

script.__file = "src/views/healthManagement/dashboard/charts/NoOfPeople.vue"

export default script