<template>
  <a-select
    v-model:value="filterStore.store_id"
    style="width: 100%"
    showSearch
    optionFilterProp="label"
  >
    <a-select-option value="">請選擇門市</a-select-option>
    <a-select-option
      :label="item.store_name"
      v-for="item in storeInfo"
      :key="item.store_id"
      :value="item.store_id"
    >
      {{ item.store_name }}
    </a-select-option>
  </a-select>
</template>

<script>
  import { reactive, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    setup(props, { emit }) {
      const store = useStore()
      let storeInfo = computed(() => store.getters['store/storeList'])

      const filterStore = reactive({
        store_id: '',
      })

      watch(
        () => filterStore,
        () => {
          emit('get-value', filterStore)
        },
        {
          deep: true,
        }
      )

      return {
        props,
        filterStore,
        storeInfo,
      }
    },
  }
</script>

<style></style>
