<template>
  <div>
    <a-table
      :columns="tableColumns"
      :data-source="data"
      :row-key="(record) => record.id"
    >
      <template #name="{ record }">
        <span>
          <a @click="handleEdit(record.id)">{{ record.title }}</a>
        </span>
      </template>
      <template #action="{ record }">
        <a-popconfirm
          title="確定要刪除此常用搜尋?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleDelete(record.id)"
        >
          <a href="#">刪除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
  import { computed, onUnmounted } from 'vue'
  import { useStore } from 'vuex'
  export default {
    props: {
      typeString: {
        type: String,
        default: 'basic',
      },
    },
    setup(props, { emit }) {
      const store = useStore()
      const handleDelete = (id) => {
        emit('delete', id)
      }
      const handleEdit = (id) => {
        emit('edit', id)
      }
      const data = computed(() => store.getters['searchSetting/settingList'])
      const tableColumns = [
        {
          title: '基本常用搜尋名稱',
          dataIndex: 'title',
          key: 'title',
          align: 'left',
          slots: { customRender: 'name' },
        },
        {
          title: '動作',
          width: 130,
          key: 'action',
          align: 'center',
          slots: { customRender: 'action' },
        },
      ]

      onUnmounted(() => {
        store.dispatch('searchSetting/setSettingList', [])
      })
      return {
        handleDelete,
        data,
        tableColumns,
        handleEdit,
      }
    },
  }
</script>

<style lang="less">
  .ant-table {
    .ant-table-thead > tr {
      th {
        text-align: center;
        height: 60px;
      }
    }
  }
</style>
