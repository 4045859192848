import { render } from "./StoreSelectorList.vue?vue&type=template&id=4dada59e"
import script from "./StoreSelectorList.vue?vue&type=script&lang=js"
export * from "./StoreSelectorList.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4dada59e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4dada59e', script)) {
    api.reload('4dada59e', script)
  }
  
  module.hot.accept("./StoreSelectorList.vue?vue&type=template&id=4dada59e", () => {
    api.rerender('4dada59e', render)
  })

}

script.__file = "src/components/StoreSelectorList.vue"

export default script