<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import moment from 'moment'
  import { getChartYAxisRange, round } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let total = [], // 項目數量
        bp = [], // 血壓
        os = [], // 血氧
        glucose = [], // 血糖
        bodyWeight = [], // 體溫
        bodyTemp = [] // 身體組成
      const option = ref(null)
      const echartInit = () => {
        // 項目數量刻度範圍
        const measuerTotalValueRange = {
          min: 0,
          max: Math.max.apply(
            Math,
            Object.values(dataCopy.value).map(
              (o) =>
                o.measurements.bpTotal +
                o.measurements.osTotal +
                o.measurements.glucoseTotal +
                o.measurements.bodyWeightTotal +
                o.measurements.bodyTempTotal
            )
          ),
        }
        const measuerTotalChartRange = getChartYAxisRange(
          measuerTotalValueRange
        )
        // 單一項目刻度範圍
        const measuerItemValueRange = {
          min: 0,
          max: Math.max.apply(
            Math,
            Object.values(dataCopy.value).map((o) =>
              Math.max(
                o.measurements.bpTotal,
                o.measurements.osTotal,
                o.measurements.glucoseTotal,
                o.measurements.bodyWeightTotal,
                o.measurements.bodyTempTotal
              )
            )
          ),
        }
        const measuerItemChartRange = getChartYAxisRange(measuerItemValueRange)

        for (const key in dataCopy.value) {
          total.push([
            key,
            dataCopy.value[key].measurements.bpTotal +
              dataCopy.value[key].measurements.osTotal +
              dataCopy.value[key].measurements.glucoseTotal +
              dataCopy.value[key].measurements.bodyWeightTotal +
              dataCopy.value[key].measurements.bodyTempTotal,
          ]) // 項目數量
          bp.push([key, dataCopy.value[key].measurements.bpTotal]) // 血壓
          os.push([key, dataCopy.value[key].measurements.osTotal]) // 血氧
          glucose.push([key, dataCopy.value[key].measurements.glucoseTotal]) // 血糖
          bodyWeight.push([
            key,
            dataCopy.value[key].measurements.bodyWeightTotal,
          ]) // 身體組成
          bodyTemp.push([key, dataCopy.value[key].measurements.bodyTempTotal]) // 體溫
        }
        const data = [
          {
            name: '項目數量',
            type: 'bar',
            barWidth: 10,
            data: total,
          },
          {
            name: '血壓',
            type: 'line',
            barWidth: 10,
            data: bp,
            yAxisIndex: 1,
          },
          {
            name: '血糖',
            type: 'line',
            barWidth: 10,
            data: glucose,
            yAxisIndex: 1,
          },
          {
            name: '血氧',
            type: 'line',
            barWidth: 10,
            data: os,
            yAxisIndex: 1,
          },
          {
            name: '體溫',
            type: 'line',
            barWidth: 10,
            data: bodyTemp,
            yAxisIndex: 1,
          },
          {
            name: '身體組成',
            type: 'line',
            barWidth: 10,
            data: bodyWeight,
            yAxisIndex: 1,
          },
        ]
        option.value = {
          backgroundColor: 'white',
          title: {
            text: '個案量測紀錄項目數',
            left: 'center',
            textStyle: {
              fontSize: '20',
            },
          },
          color: [
            '#BB6BD9',
            '#EB5757',
            '#F2994A',
            '#F2C94C',
            '#219653',
            '#2F80ED',
          ],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `<table>
                <tr>
                  <td>量測日期</td>
                  <td align="right" colspan=2>
                  ${moment(params[0].value[0]).format('YYYY-MM-DD')}</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[0].color
                  };"></span>量測項目數：</td>
                  <td align="right">${params[0].data[1]}筆</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[1].color
                  };"></span>血壓：</td>
                  <td align="right">${params[1].data[1]}筆</td>
                     <td align="right">${round(
                       (params[1].data[1] / params[0].data[1]) * 100,
                       1
                     )}%</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[2].color
                  };"></span>血糖：</td>
                  <td align="right">${params[2].data[1]}筆</td>
                     <td align="right">${round(
                       (params[2].data[1] / params[0].data[1]) * 100,
                       1
                     )}%</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[3].color
                  };"></span>血氧：</td>
                  <td align="right">${params[3].data[1]}筆</td>
                     <td align="right">${round(
                       (params[3].data[1] / params[0].data[1]) * 100,
                       1
                     )}%</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[4].color
                  };"></span>體溫：</td>
                  <td align="right">${params[4].data[1]}筆</td>
                     <td align="right">${round(
                       (params[4].data[1] / params[0].data[1]) * 100,
                       1
                     )}%</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[5].color
                  };"></span>身體組成：</td>
                  <td align="right">${params[5].data[1]}筆</td>
                     <td align="right">${round(
                       (params[5].data[1] / params[0].data[1]) * 100,
                       1
                     )}%</td>
                </tr>
                </table>`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['項目數量', '血壓', '血糖', '血氧', '體溫', '身體組成'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '人數',
              min: measuerTotalChartRange.min,
              max: measuerTotalChartRange.max,
              interval: measuerTotalChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
              nameLocation: 'start',
              nameTextStyle: { align: 'right' },
              offset: 14,
            },
            {
              type: 'value',
              name: '人次',
              min: measuerItemChartRange.min,
              max: measuerItemChartRange.max,
              interval: measuerItemChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
              nameLocation: 'start',
              nameTextStyle: { align: 'left' },
              offset: 14,
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style lang="less"></style>
