<template>
  <div class="accountManagement-container">
    <a-page-header title="會員管理">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <CustSearch :filter="filter" @search="handleSearch" />
    <CustDataTable
      v-model:searchResult="searchResult"
      @action1="handelAction1"
      @action2="handelAction2"
    />
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'

  import CustSearch from '@/components/CustSearch'
  import CustDataTable from '@/components/CustDataTable'
  export default {
    name: 'accountManagement',
    components: {
      QuestionCircleOutlined,
      CustSearch,
      CustDataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const filter = computed(() => store.getters['cust/filter'])
      const searchResult = computed(() => {
        const data = store.getters['cust/searchResult']
        if (typeof data === 'undefined' || data.length === 0) {
          return []
        }
        data.map((item) => {
          if (item.record) {
            item.action1 = '量測記錄'
          }
          item.action2 = '啟用健康服務'
          item.action3 = '瀏覽個案資料'
        })
        return data
      })

      const handleSearch = () => {
        store.dispatch('cust/search')
      }

      const handelAction1 = () => {
        console.log('handelAction1')
      }

      const handelAction2 = (memberID) => {
        router.push({ path: `/purchaseServicePlan/${memberID}` })
      }

      const handelAction3 = (memberID) => {
        router.push({ path: `/purchaseServicePlan/${memberID}` })
      }

      return {
        filter,
        searchResult,
        handleSearch,
        handelAction1,
        handelAction2,
        handelAction3,
      }
    },
  }
</script>
<style lang="less">
  .accountManagement-container {
  }
</style>
