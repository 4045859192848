import { render } from "./SettingRuleString.vue?vue&type=template&id=13a2ff3e"
import script from "./SettingRuleString.vue?vue&type=script&lang=js"
export * from "./SettingRuleString.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "13a2ff3e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('13a2ff3e', script)) {
    api.reload('13a2ff3e', script)
  }
  
  module.hot.accept("./SettingRuleString.vue?vue&type=template&id=13a2ff3e", () => {
    api.rerender('13a2ff3e', render)
  })

}

script.__file = "src/views/healthManagement/healthRecord/SettingRuleString.vue"

export default script