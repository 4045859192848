<template>
  <div>
    <!-- 類別 -->
    <div>常用搜尋名稱: 【{{ rules.title }}】</div>
    <div>量測類別: {{ typeName }}</div>

    <a-row :gutter="2" v-for="(rule, index) in rules.clauses" :key="index">
      <!-- And/Or -->
      <a-col :span="2">
        <div v-if="rule.sets">
          <span v-if="rule.sets == 'and'">並且</span>
          <span v-if="rule.sets == 'or'">或者</span>
        </div>
      </a-col>

      <!-- 項目 -->
      <a-col :span="3">
        {{ getItemName(rule.subject) }}
      </a-col>

      <!-- 運算子 -->
      <a-col :span="2">
        <span v-if="rule.predicate == 'bw'">介於</span>
        <span v-else-if="rule.predicate == 'nbw'">不介於</span>
        <span v-else-if="rule.predicate == 'gt'">大於</span>
        <span v-else-if="rule.predicate == 'ge'">大於等於</span>
        <span v-else-if="rule.predicate == 'lt'">小於</span>
        <span v-else-if="rule.predicate == 'le'">小於等於</span>
        <span v-else-if="rule.predicate == 'eg'">等於</span>
        <span v-else>等於</span>
      </a-col>

      <a-col :span="2">
        <span v-if="typeof rule.component == 'object'">
          {{ rule.component[0] }}~{{ rule.component[1] }}
        </span>
        <span v-else-if="typeof rule.component == 'number'">
          {{ rule.component }}
        </span>
        <span v-else>
          {{ getComponentString(rule.component) }}
        </span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import { toRefs } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'settingRule',
    components: {},
    props: {
      typeString: {
        type: String,
        default: 'basic',
      },
      rules: {
        type: Object,
      },
    },
    setup(props) {
      const store = useStore()
      const { rules } = toRefs(props)

      const getComponentString = (value) => {
        let componentString = ''
        switch (value) {
          case 'fasting':
            componentString = ' 空腹'
            break
          case 'before_meal':
            componentString = ' 餐前'
            break
          case 'after_meal':
            componentString = ' 餐後'
            break
        }
        return componentString
      }

      // 篩選項目架構
      store.dispatch('searchSetting/initSchema', 'basic')
      const schema = store.getters['searchSetting/schema']

      // 取得常用收尋類別資料
      const getRuleType = () => {
        return [...schema].find((item) => item.type == rules.value.type)
      }
      const typeName = getRuleType().name

      const getItemName = (subject) => {
        return getRuleType().items.find((item) => item.subject == subject).name
      }

      return {
        schema,
        typeName,
        getItemName,
        getComponentString,
      }
    },
  }
</script>

<style></style>
