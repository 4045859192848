const accessTokens = {
  admin: 'admin-accessToken',
  editor: 'editor-accessToken',
  test: 'test-accessToken',
}

module.exports = [
  // 登入認證
  {
    url: '/yclogin',
    type: 'post',
    response(config) {
      const { id } = config.body
      const accessToken = accessTokens[id]
      if (!accessToken) {
        return {
          found: false,
          message: '帳號或密碼錯誤！',
        }
      }
      return {
        found: true,
        message: '成功',
        sessionId: accessToken,
      }
    },
  },
  // 取得登入員工資料
  {
    url: '/staffInfo',
    type: 'get',
    response() {
      return {
        branches: [
          { id: 217, name: 'A1115石牌健康人生藥局' },
          { id: 5, name: '1401佑全中壢中山藥局' },
        ],
        found: true,
        message: '成功',
        name: '黃千文',
        account: '08720',
        id: '5025',
      }
    },
  },
  // 登出
  {
    url: '/logout',
    type: 'post',
    response() {
      return {
        code: 200,
        msg: 'success',
      }
    },
  },
  // 用員工ID或員工編號取得使用者資料
  {
    url: '/staves',
    type: 'get',
    response() {
      return {
        found: true,
        staffId: 6274,
        loginName: '09777',
        name: '郭姿儀',
      }
    },
  },
]
