import { render } from "./SettingRule.vue?vue&type=template&id=634db2a0"
import script from "./SettingRule.vue?vue&type=script&lang=js"
export * from "./SettingRule.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "634db2a0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('634db2a0', script)) {
    api.reload('634db2a0', script)
  }
  
  module.hot.accept("./SettingRule.vue?vue&type=template&id=634db2a0", () => {
    api.rerender('634db2a0', render)
  })

}

script.__file = "src/views/healthManagement/healthRecord/SettingRule.vue"

export default script