import { render } from "./VersionInformation.vue?vue&type=template&id=1b080374&scoped=true"
import script from "./VersionInformation.vue?vue&type=script&lang=js"
export * from "./VersionInformation.vue?vue&type=script&lang=js"

import "./VersionInformation.vue?vue&type=style&index=0&id=1b080374&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-1b080374"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1b080374"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1b080374', script)) {
    api.reload('1b080374', script)
  }
  
  module.hot.accept("./VersionInformation.vue?vue&type=template&id=1b080374&scoped=true", () => {
    api.rerender('1b080374', render)
  })

}

script.__file = "src/views/index/components/VersionInformation.vue"

export default script