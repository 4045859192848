const { mock } = require('mockjs')

module.exports = [
  // 以會員編號取得個案資料
  {
    url: '/contracts',
    type: 'get',
    response(config) {
      const { state = '' } = config.query
      return {
        result: [
          {
            id: 5,
            startDate: '2021/08/06',
            expireDate: '2021/11/06',
            activationCode: 'GWJTG41980',
            serviceStatus:
              state == '' ? mock('@integer(0, 5)') : parseInt(state),
            case: '健康方案-900',
            startName: '郭姿儀',
          },
        ],
      }
    },
  },
  // 以會員編號取得合約商品使用紀錄
  {
    url: '/contractGoodsUsages/member/query',
    type: 'post',
    response() {
      return [
        {
          id: 2,
          date: '2021-08-03 17:05:56',
          activationCode: 'GWJTG41980',
          sellStatus: 0,
          receiveStore: '2327清水中山三店',
          operatorId: '09777',
          receiveItemId: 1,
          useQuota: 1,
        },
        {
          id: 3,
          date: '2021-08-03 17:11:52',
          activationCode: 'GWJTG41980',
          sellStatus: 0,
          receiveStore: '2327清水中山三店',
          operatorId: '09777',
          receiveItemId: 1,
          useQuota: 1,
        },
      ]
    },
  },
  // 以合約編號取得合約商品使用紀錄
  {
    url: '/contractGoodsUsages/query',
    type: 'post',
    response() {
      return [
        {
          id: 2,
          date: '2021-08-03 17:05:56',
          activationCode: 'GWJTG41980',
          sellStatus: 0,
          receiveStore: '2327清水中山三店',
          operatorId: '09777',
          receiveItemId: 1,
          useQuota: 1,
        },
        {
          id: 3,
          date: '2021-08-03 17:11:52',
          activationCode: 'GWJTG41980',
          sellStatus: 0,
          receiveStore: '2327清水中山三店',
          operatorId: '09777',
          receiveItemId: 1,
          useQuota: 1,
        },
      ]
    },
  },
  // 記錄合約商品的使用
  {
    url: '/contractGoodsUsages',
    type: 'post',
    response() {
      return {
        ok: true,
      }
    },
  },
  // 變更合約商品使用紀錄的狀態
  {
    url: '/contractGoodsUsages',
    type: 'patch',
    response() {
      return {
        ok: true,
      }
    },
  },
  // 以個案管理常用搜尋取得個案與量測資料
  {
    url: '/members/rule/query',
    type: 'post',
    response(config) {
      const { type } = config.body
      const healthRecord = []
      healthRecord['bloodPressure'] = {
        systolic: mock('@integer(60, 120)'),
        diastolic: mock('@integer(60, 120)'),
        pulse: mock('@integer(60, 120)'),
        pulsePressureDifference: mock('@integer(0,30)'),
        arr: mock('@integer(0,10)'),
      }
      healthRecord['weight'] = {
        weight: mock('@integer(60, 120)'),
        bmi: mock('@integer(0, 35)'),
        bmr: mock('@integer(1000, 2000)'),
        body_fat: mock('@integer(0, 50)'),
        muscle_mass: mock('@integer(0, 30)'),
        visceral_fat: mock('@integer(0, 50)'),
      }
      healthRecord['spO2'] = {
        spo2_lowest: mock('@integer(85, 100)'),
      }
      healthRecord['bodyTemperature'] = {
        temperature: mock('@integer(34, 41)'),
      }
      healthRecord['bloodGlucose'] = {
        bloodGlucoseFasting: mock('@integer(34, 41)'),
        bloodGlucoseBeforeMeal: mock('@integer(34, 41)'),
        bloodGlucoseAfterMeal: mock('@integer(34, 41)'),
      }
      return {
        result: [
          {
            memberID: '1655108',
            type: type,
            frequency: '@integer(1, 50)',
            avgRecord: {
              ...healthRecord[type],
            },
          },
        ],
      }
    },
  },
  // 以門市搜尋個案資料(棄用)
  {
    url: '/measurements/location/\\d{1,3}/case',
    type: 'get',
    response() {
      return {
        data: [
          {
            id: 37,
            created: '2021-04-25T13:22:06.073Z',
            updated: '2021-04-25T13:22:06.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-29T06:11:04.000Z',
                diastolic: 86,
                systolic: 135,
                pulse: 73,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-29T06:11:04.000Z',
                actHighest: 4,
                pulseHighest: 78,
                pulseLowest: 72,
                spo2Highest: 100,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 37,
                created: '2021-04-25T13:22:06.074Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-29T06:11:04.000Z',
                bloodGlucose: 137,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-29T06:11:04.000Z',
                bodyTemperature: 37.4,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 37,
                created: '2021-04-25T13:22:06.072Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-29T06:11:04.000Z',
                weight: 67.7376,
                bmi: 24,
                bmr: 760.801,
                bodyFat: 16.7,
                bodyWater: 40.059,
                muscleMass: 28.4498,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 32,
            created: '2021-04-25T13:22:05.132Z',
            updated: '2021-04-25T13:22:05.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 32,
                created: '2021-04-25T13:22:05.132Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-14T06:04:56.000Z',
                diastolic: 89,
                systolic: 133,
                pulse: 72,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 44,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 32,
                created: '2021-04-25T13:22:05.131Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-14T06:04:56.000Z',
                actHighest: 3,
                pulseHighest: 78,
                pulseLowest: 73,
                spo2Highest: 99,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 32,
                created: '2021-04-25T13:22:05.133Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-14T06:04:56.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 32,
                created: '2021-04-25T13:22:05.134Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-14T06:04:56.000Z',
                bodyTemperature: 37,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 32,
                created: '2021-04-25T13:22:05.133Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-14T06:04:56.000Z',
                weight: 69.1488,
                bmi: 24.5,
                bmr: 762.734,
                bodyFat: 18,
                bodyWater: 40.5334,
                muscleMass: 28.351,
                visceralFat: 5,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 34,
            created: '2021-04-25T13:22:05.499Z',
            updated: '2021-04-25T13:22:05.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 34,
                created: '2021-04-25T13:22:05.499Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-20T06:23:22.000Z',
                diastolic: 89,
                systolic: 133,
                pulse: 71,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 44,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-20T06:23:22.000Z',
                actHighest: 3,
                pulseHighest: 79,
                pulseLowest: 75,
                spo2Highest: 99,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-20T06:23:22.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-20T06:23:22.000Z',
                bodyTemperature: 37.2,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 34,
                created: '2021-04-25T13:22:05.499Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-20T06:23:22.000Z',
                weight: 68.5843,
                bmi: 24.3,
                bmr: 761.961,
                bodyFat: 18,
                bodyWater: 40.3436,
                muscleMass: 28.1196,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 33,
            created: '2021-04-25T13:22:05.297Z',
            updated: '2021-04-25T13:22:05.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-17T06:39:58.000Z',
                diastolic: 87,
                systolic: 138,
                pulse: 70,
                afib: false,
                arr: true,
                pc: false,
                ihb: false,
                pulsePressureDifference: 51,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-17T06:39:58.000Z',
                actHighest: 4,
                pulseHighest: 79,
                pulseLowest: 73,
                spo2Highest: 100,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 33,
                created: '2021-04-25T13:22:05.297Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-17T06:39:58.000Z',
                bloodGlucose: 139,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 33,
                created: '2021-04-25T13:22:05.299Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-17T06:39:58.000Z',
                bodyTemperature: 37.2,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-17T06:39:58.000Z',
                weight: 70.2778,
                bmi: 24.9,
                bmr: 764.281,
                bodyFat: 16.9,
                bodyWater: 40.913,
                muscleMass: 28.8139,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 36,
            created: '2021-04-25T13:22:05.894Z',
            updated: '2021-04-25T13:22:05.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-26T06:32:12.000Z',
                diastolic: 86,
                systolic: 134,
                pulse: 79,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 48,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-26T06:32:12.000Z',
                actHighest: 4,
                pulseHighest: 78,
                pulseLowest: 74,
                spo2Highest: 100,
                spo2Lowest: 98,
              },
            ],
            glucoseMeasurements: [
              {
                id: 36,
                created: '2021-04-25T13:22:05.896Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-26T06:32:12.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-26T06:32:12.000Z',
                bodyTemperature: 37,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 36,
                created: '2021-04-25T13:22:05.906Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-26T06:32:12.000Z',
                weight: 69.1488,
                bmi: 24.5,
                bmr: 762.734,
                bodyFat: 16.2,
                bodyWater: 40.5334,
                muscleMass: 29.0425,
                visceralFat: 5,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 35,
            created: '2021-04-25T13:22:05.678Z',
            updated: '2021-04-25T13:22:05.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 35,
                created: '2021-04-25T13:22:05.678Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-23T06:10:33.000Z',
                diastolic: 87,
                systolic: 132,
                pulse: 74,
                afib: true,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 45,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 35,
                created: '2021-04-25T13:22:05.680Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-23T06:10:33.000Z',
                actHighest: 3,
                pulseHighest: 77,
                pulseLowest: 70,
                spo2Highest: 99,
                spo2Lowest: 98,
              },
            ],
            glucoseMeasurements: [
              {
                id: 35,
                created: '2021-04-25T13:22:05.679Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-23T06:10:33.000Z',
                bloodGlucose: 130,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 35,
                created: '2021-04-25T13:22:05.680Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-23T06:10:33.000Z',
                bodyTemperature: 37.3,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 35,
                created: '2021-04-25T13:22:05.679Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-23T06:10:33.000Z',
                weight: 69.9955,
                bmi: 24.8,
                bmr: 763.894,
                bodyFat: 17.9,
                bodyWater: 40.8181,
                muscleMass: 28.6982,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 31,
            created: '2021-04-25T13:22:04.964Z',
            updated: '2021-04-25T13:22:04.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 31,
                created: '2021-04-25T13:22:04.955Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-11T06:43:41.000Z',
                diastolic: 85,
                systolic: 134,
                pulse: 71,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-11T06:43:41.000Z',
                actHighest: 4,
                pulseHighest: 77,
                pulseLowest: 70,
                spo2Highest: 98,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-11T06:43:41.000Z',
                bloodGlucose: 133,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-11T06:43:41.000Z',
                bodyTemperature: 37.4,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 31,
                created: '2021-04-25T13:22:04.964Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-11T06:43:41.000Z',
                weight: 67.7376,
                bmi: 24,
                bmr: 760.801,
                bodyFat: 17.3,
                bodyWater: 40.059,
                muscleMass: 28.4498,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 30,
            created: '2021-04-25T13:22:04.791Z',
            updated: '2021-04-25T13:22:04.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-08T06:41:19.000Z',
                diastolic: 89,
                systolic: 136,
                pulse: 73,
                afib: true,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 47,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 30,
                created: '2021-04-25T13:22:04.794Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-08T06:41:19.000Z',
                actHighest: 3,
                pulseHighest: 79,
                pulseLowest: 70,
                spo2Highest: 100,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-08T06:41:19.000Z',
                bloodGlucose: 139,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 30,
                created: '2021-04-25T13:22:04.791Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-08T06:41:19.000Z',
                bodyTemperature: 37.4,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-08T06:41:19.000Z',
                weight: 69.431,
                bmi: 24.6,
                bmr: 763.121,
                bodyFat: 17.6,
                bodyWater: 40.6283,
                muscleMass: 27.7724,
                visceralFat: 5,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 29,
            created: '2021-04-25T13:22:04.628Z',
            updated: '2021-04-25T13:22:04.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-05T06:47:58.000Z',
                diastolic: 86,
                systolic: 135,
                pulse: 74,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-05T06:47:58.000Z',
                actHighest: 3,
                pulseHighest: 80,
                pulseLowest: 70,
                spo2Highest: 98,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 29,
                created: '2021-04-25T13:22:04.630Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-05T06:47:58.000Z',
                bloodGlucose: 136,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-05T06:47:58.000Z',
                bodyTemperature: 37.1,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 29,
                created: '2021-04-25T13:22:04.630Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-05T06:47:58.000Z',
                weight: 68.8666,
                bmi: 24.4,
                bmr: 762.347,
                bodyFat: 16.8,
                bodyWater: 40.4385,
                muscleMass: 28.2353,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
          {
            id: 28,
            created: '2021-04-25T13:22:04.436Z',
            updated: '2021-04-25T13:22:04.000Z',
            hubCode: 'YC111',
            subject: {
              id: 2,
              created: '2021-04-25T13:22:04.282Z',
              updated: '2021-05-02T07:38:59.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: true,
              enjoyment: {
                id: 1,
                created: '2021-05-02T07:38:59.934Z',
                updated: '2021-05-02T07:38:59.934Z',
                __belongsToContracts__: [
                  {
                    id: 1,
                    created: '2021-05-02T07:38:59.990Z',
                    updated: '2021-05-02T07:38:59.990Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'GNNKH63837',
                    state: 0,
                  },
                  {
                    id: 3,
                    created: '2021-05-02T07:58:56.216Z',
                    updated: '2021-05-02T07:58:56.216Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'XDGHZ39612',
                    state: 0,
                  },
                  {
                    id: 5,
                    created: '2021-05-02T15:09:18.552Z',
                    updated: '2021-05-02T15:09:18.552Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'DNAKN67553',
                    state: 0,
                  },
                  {
                    id: 4,
                    created: '2021-05-02T08:00:30.528Z',
                    updated: '2021-05-02T08:00:30.528Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'FDPGU49080',
                    state: 0,
                  },
                  {
                    id: 6,
                    created: '2021-05-04T12:25:49.909Z',
                    updated: '2021-05-04T12:25:49.909Z',
                    startTime: '2021-05-03T16:00:00.000Z',
                    endTime: '2021-06-03T16:00:00.000Z',
                    activationCode: 'WLJVS85656',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-05-02T07:57:17.106Z',
                    updated: '2021-05-02T07:57:17.106Z',
                    startTime: '2021-05-01T16:00:00.000Z',
                    endTime: '2021-06-01T16:00:00.000Z',
                    activationCode: 'RUKKS58998',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 28,
                created: '2021-04-25T13:22:04.436Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-02T06:49:27.000Z',
                diastolic: 86,
                systolic: 139,
                pulse: 72,
                afib: false,
                arr: true,
                pc: false,
                ihb: false,
                pulsePressureDifference: 53,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 28,
                created: '2021-04-25T13:22:04.438Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-02T06:49:27.000Z',
                actHighest: 3,
                pulseHighest: 76,
                pulseLowest: 72,
                spo2Highest: 99,
                spo2Lowest: 98,
              },
            ],
            glucoseMeasurements: [
              {
                id: 28,
                created: '2021-04-25T13:22:04.437Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-02T06:49:27.000Z',
                bloodGlucose: 138,
                meal: 'Postprandial',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 28,
                created: '2021-04-25T13:22:04.438Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-02T06:49:27.000Z',
                bodyTemperature: 37.4,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 28,
                created: '2021-04-25T13:22:04.437Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-02T06:49:27.000Z',
                weight: 69.7133,
                bmi: 24.7,
                bmr: 763.507,
                bodyFat: 16.9,
                bodyWater: 40.7232,
                muscleMass: 29.2796,
                visceralFat: 4,
              },
            ],
            location: {
              id: 1,
              created: '2021-04-13T13:33:19.367Z',
              updated: '2021-04-13T13:33:19.367Z',
              code: '111',
              title: '1101瑞光藥局',
              staffs: [],
            },
          },
        ],
      }
    },
  },
]
