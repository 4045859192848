<template>
  <a-alert :message="YC" type="success" show-icon />

  <a-card class="version-information">
    <template v-slot:title>系统信息</template>
    <template v-slot:extra>
      <a href="#">部署时间{{ updateTime }}</a>
    </template>
    <div class="version-information-table">
      <table>
        <tr>
          <td>标星</td>
          <td>
            <a
              target="_blank"
              href="https://github.com/chuzhixin/vue-admin-beautiful/tree/vue3.0-antdv"
            >
              <img
                style="margin-right: 10px"
                src="https://img.shields.io/github/stars/chuzhixin/vue-admin-beautiful?style=flat-square&label=Stars&logo=github"
              />
            </a>
          </td>
          <td>下载量统计</td>
          <td>敬请期待！</td>
        </tr>
        <tr>
          <td>vue</td>
          <td>@vue/cli</td>
        </tr>
        <tr>
          <td>vuex</td>
          <td>vue-router</td>
        </tr>
        <tr>
          <td>eslint-plugin-vue</td>
          <td>axios</td>
        </tr>
        <tr>
          <td>babel-eslint</td>
          <td>ant-design-vue</td>
        </tr>
        <tr>
          <td>v2.x版演示地址（MIT协议，商用免费）</td>
          <td colspan="3">
            <a
              href="https://chu1204505056.gitee.io/vue-admin-beautiful-element/"
            >
              https://chu1204505056.gitee.io/vue-admin-beautiful-element/
            </a>
          </td>
        </tr>
        <tr>
          <td>v2.x版开源地址（MIT协议，商用免费）</td>
          <td colspan="3">
            <a
              href="https://github.com/chuzhixin/vue-admin-beautiful/tree/master"
            >
              https://github.com/chuzhixin/vue-admin-beautiful/tree/master
            </a>
          </td>
        </tr>
        <tr>
          <td>v3.x版演示地址（MIT协议，商用免费）</td>
          <td colspan="3">
            <a href="https://chu1204505056.gitee.io/vue-admin-beautiful-antdv/">
              https://chu1204505056.gitee.io/vue-admin-beautiful/
            </a>
          </td>
        </tr>
        <tr>
          <td>v3.x版开源地址（MIT协议，商用免费）</td>
          <td colspan="3">
            <a
              href="https://github.com/chuzhixin/vue-admin-beautiful/tree/vue3.0-antdv"
            >
              https://github.com/chuzhixin/vue-admin-beautiful/tree/vue3.0-antdv
            </a>
          </td>
        </tr>
        <tr>
          <td>PRO版演示地址</td>
          <td colspan="3">
            <a href="https://chu1204505056.gitee.io/vue-admin-beautiful-pro/">
              https://chu1204505056.gitee.io/vue-admin-beautiful-pro/
            </a>
          </td>
        </tr>
        <tr>
          <td>官方QQ群</td>
          <td colspan="3">972435319、1139183756</td>
        </tr>
      </table>
    </div>
  </a-card>
</template>
<script>
  export default {
    data() {
      return {
        updateTime: process.env.VUE_APP_UPDATE_TIME,
      }
    },
  }
</script>
<style lang="less" scoped>
  .version-information {
    margin-top: @vab-margin;
    &-table {
      width: 100%;
      overflow: scroll;
      table {
        width: 100%;
        color: #666;
        border-collapse: collapse;
        background-color: #fff;

        td {
          position: relative;
          padding: 9px 15px;
          font-size: 14px;
          line-height: 20px;
          border: 1px solid #e6e6e6;

          &:nth-child(odd) {
            width: 20%;
            text-align: right;
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
</style>
