const { mock } = require('mockjs')

module.exports = [
  // 取得健康方案
  {
    url: '/healthCases',
    type: 'get',
    response() {
      return {
        found: true,
        message: '成功',
        data: [
          {
            prod_id: 213021,
            barcode: '4710366311110',
            prod_name: '健康方案-900',
          },
          {
            prod_id: 251616,
            barcode: '76701408',
            prod_name: '健康方案-1200',
          },
          {
            prod_id: 251617,
            barcode: '76701409',
            prod_name: '健康方案-600',
          },
        ],
      }
    },
  },
  // 新建健康管理合約
  {
    url: '/contracts',
    type: 'post',
    response() {
      return {
        message: '合約已建立。 (方案編號：213021)',
      }
    },
  },
  // 個案量測資料(棄用)
  {
    url: '/healthy/getRecordDetail',
    type: 'post',
    response() {
      return {
        code: 200,
        msg: 'success',
        data: {
          recordNo: 1,
          date: '2020/08/01 12:59:00',
          createdTime: '2020/08/01 12:59:00',
          source: 'STM 300F(XXXXXX)',
          user: {
            name: '小明',
            cust_id: '128489',
            level: '一般會員',
            caseNo: '0101359648',
            store: '1101瑞光藥局',
            phone: '02-87654321',
          },
          bloodPressure: {
            systolic: 135,
            diastolic: 88,
            pulse: 73,
            AFib: false,
            ARR: true,
            PC: true,
            IHB: false,
            deviceSKU: 'RM_BP_X3',
            createdTime: 1586760141,
            measureTime: 1586761180,
            deviceTime: 1586761180,
          },
          bodyTemperature: {
            bodyTemperature: 36.5,
            deviceSKU: 'RM_TM_HC700',
            createdTime: 1586760141,
            measureTime: 1586761004,
            deviceTime: 1586761004,
          },
          bloodGlucose: {
            bloodGlucose: 90,
            meal: 'fasting',
            deviceSKU: 'RM_BG_HT100',
            createdTime: 1586760141,
            measureTime: 1586761686,
            deviceTime: 1586761686,
          },
          weight: {
            weight: 51.6,
            BMI: 22.63783338416139,
            BMR: 1271.180002117157,
            bodyFat: 25.79478977097793,
            bodyWater: 54.35241627061109,
            muscleMass: 16.86973233019662,
            visceraFat: 5,
            deviceSKU: 'RM_BF_LS212-B',
            createdTime: 1586760141,
            measureTime: 1586762552,
            deviceTime: 1586762552,
          },
          spo2: {
            ACTHighest: 2,
            pulseHighest: 83,
            pulseLowest: 83,
            spO2Highest: 97,
            spO2Lowest: 97,
            duration: 3,
            deviceSKU: 'RM_PO_SA300',
            createdTime: 1586760141,
            measureTime: 1586762244,
            deviceTime: 1586762244,
          },
        },
      }
    },
  },
  // 取得指定姓名與店家與時間範圍的會員量測資料
  {
    url: '/measurements/location/\\d{1,3}/member',
    type: 'get',
    response(config) {
      const { responseFormat = '' } = config.query

      if (responseFormat == 'records') {
        return {
          data: [
            {
              id: 22,
              created: '2021-04-27T11:53:31.793Z',
              updated: '2021-04-27T11:53:31.000Z',
              hubCode: 'YC001',
              subject: {
                id: 20,
                created: '2021-04-14T04:11:33.332Z',
                updated: '2021-04-26T07:04:14.000Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                hasOwnedCard: false,
                enjoyment: {
                  id: 1,
                  created: '2021-04-26T07:04:14.337Z',
                  updated: '2021-04-26T07:04:14.337Z',
                  __belongsToContracts__: [
                    {
                      id: 3,
                      created: '2021-04-26T07:08:03.027Z',
                      updated: '2021-04-26T07:08:03.027Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 2,
                      created: '2021-04-26T07:06:48.750Z',
                      updated: '2021-04-26T07:06:48.750Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 1,
                      created: '2021-04-26T07:04:14.401Z',
                      updated: '2021-04-26T07:04:14.401Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                  ],
                },
                cache: {
                  id: 105,
                  created: '2021-04-23T04:03:04.010Z',
                  updated: '2021-04-23T04:03:04.010Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              bpMeasurements: [
                {
                  id: 7,
                  created: '2021-04-27T11:53:31.790Z',
                  updated: '2021-04-27T11:53:31.000Z',
                  measured: '2020-04-13T06:59:40.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X3',
                  deviceTime: '2020-04-13T06:59:40.000Z',
                  diastolic: 87,
                  systolic: 181,
                  pulse: 73,
                  afib: false,
                  arr: true,
                  pc: true,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                },
              ],
              oxygenSatMeasurements: [
                {
                  id: 7,
                  created: '2021-04-27T11:53:31.793Z',
                  updated: '2021-04-27T11:53:31.000Z',
                  measured: '2020-04-13T07:17:24.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SA300',
                  deviceTime: '2020-04-13T07:17:24.000Z',
                  actHighest: 2,
                  pulseHighest: 83,
                  pulseLowest: 83,
                  spo2Highest: 97,
                  spo2Lowest: 97,
                },
              ],
              glucoseMeasurements: [
                {
                  id: 7,
                  created: '2021-04-27T11:53:31.793Z',
                  updated: '2021-04-27T11:53:31.000Z',
                  measured: '2020-04-13T07:08:06.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2020-04-13T07:08:06.000Z',
                  bloodGlucose: 90,
                  meal: 'Fasting',
                },
              ],
              bodyTempMeasurements: [
                {
                  id: 7,
                  created: '2021-04-27T11:53:31.793Z',
                  updated: '2021-04-27T11:53:31.000Z',
                  measured: '2020-04-13T06:56:44.000Z',
                  isNormal: true,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2020-04-13T06:56:44.000Z',
                  bodyTemperature: 36.5,
                },
              ],
              bodyWeightFatMeasurements: [
                {
                  id: 7,
                  created: '2021-04-27T11:53:31.793Z',
                  updated: '2021-04-27T11:53:31.000Z',
                  measured: '2020-04-13T07:22:32.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2020-04-13T07:22:32.000Z',
                  weight: 51.6,
                  bmi: 22.6378,
                  bmr: 1271.18,
                  bodyFat: 25.7948,
                  bodyWater: 54.3524,
                  muscleMass: 16.8697,
                  visceralFat: 5,
                },
              ],
              location: {
                id: 2,
                created: '2021-04-13T00:40:16.023Z',
                updated: '2021-04-13T00:40:16.023Z',
                code: '265',
                title: '2327清水中山三店',
                staffs: [],
              },
            },
            {
              id: 21,
              created: '2021-04-27T11:53:10.410Z',
              updated: '2021-04-27T11:53:10.000Z',
              hubCode: 'YC001',
              subject: {
                id: 20,
                created: '2021-04-14T04:11:33.332Z',
                updated: '2021-04-26T07:04:14.000Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                hasOwnedCard: false,
                enjoyment: {
                  id: 1,
                  created: '2021-04-26T07:04:14.337Z',
                  updated: '2021-04-26T07:04:14.337Z',
                  __belongsToContracts__: [
                    {
                      id: 3,
                      created: '2021-04-26T07:08:03.027Z',
                      updated: '2021-04-26T07:08:03.027Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 2,
                      created: '2021-04-26T07:06:48.750Z',
                      updated: '2021-04-26T07:06:48.750Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 1,
                      created: '2021-04-26T07:04:14.401Z',
                      updated: '2021-04-26T07:04:14.401Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                  ],
                },
                cache: {
                  id: 105,
                  created: '2021-04-23T04:03:04.010Z',
                  updated: '2021-04-23T04:03:04.010Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              bpMeasurements: [
                {
                  id: 6,
                  created: '2021-04-27T11:53:10.564Z',
                  updated: '2021-04-27T11:53:10.000Z',
                  measured: '2020-04-13T06:59:40.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X3',
                  deviceTime: '2020-04-13T06:59:40.000Z',
                  diastolic: 87,
                  systolic: 136,
                  pulse: 73,
                  afib: false,
                  arr: true,
                  pc: true,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                },
              ],
              oxygenSatMeasurements: [
                {
                  id: 6,
                  created: '2021-04-27T11:53:10.566Z',
                  updated: '2021-04-27T11:53:10.000Z',
                  measured: '2020-04-13T07:17:24.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SA300',
                  deviceTime: '2020-04-13T07:17:24.000Z',
                  actHighest: 2,
                  pulseHighest: 83,
                  pulseLowest: 83,
                  spo2Highest: 97,
                  spo2Lowest: 97,
                },
              ],
              glucoseMeasurements: [
                {
                  id: 6,
                  created: '2021-04-27T11:53:10.671Z',
                  updated: '2021-04-27T11:53:10.000Z',
                  measured: '2020-04-13T07:08:06.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2020-04-13T07:08:06.000Z',
                  bloodGlucose: 90,
                  meal: 'Fasting',
                },
              ],
              bodyTempMeasurements: [
                {
                  id: 6,
                  created: '2021-04-27T11:53:10.577Z',
                  updated: '2021-04-27T11:53:10.000Z',
                  measured: '2020-04-13T06:56:44.000Z',
                  isNormal: true,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2020-04-13T06:56:44.000Z',
                  bodyTemperature: 36.5,
                },
              ],
              bodyWeightFatMeasurements: [
                {
                  id: 6,
                  created: '2021-04-27T11:53:10.663Z',
                  updated: '2021-04-27T11:53:10.000Z',
                  measured: '2020-04-13T07:22:32.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2020-04-13T07:22:32.000Z',
                  weight: 51.6,
                  bmi: 22.6378,
                  bmr: 1271.18,
                  bodyFat: 25.7948,
                  bodyWater: 54.3524,
                  muscleMass: 16.8697,
                  visceralFat: 5,
                },
              ],
              location: {
                id: 2,
                created: '2021-04-13T00:40:16.023Z',
                updated: '2021-04-13T00:40:16.023Z',
                code: '265',
                title: '2327清水中山三店',
                staffs: [],
              },
            },
            {
              id: 20,
              created: '2021-04-23T04:19:47.820Z',
              updated: '2021-04-23T04:19:47.000Z',
              hubCode: 'YC001',
              subject: {
                id: 20,
                created: '2021-04-14T04:11:33.332Z',
                updated: '2021-04-26T07:04:14.000Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                hasOwnedCard: false,
                enjoyment: {
                  id: 1,
                  created: '2021-04-26T07:04:14.337Z',
                  updated: '2021-04-26T07:04:14.337Z',
                  __belongsToContracts__: [
                    {
                      id: 3,
                      created: '2021-04-26T07:08:03.027Z',
                      updated: '2021-04-26T07:08:03.027Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 2,
                      created: '2021-04-26T07:06:48.750Z',
                      updated: '2021-04-26T07:06:48.750Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 1,
                      created: '2021-04-26T07:04:14.401Z',
                      updated: '2021-04-26T07:04:14.401Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                  ],
                },
                cache: {
                  id: 105,
                  created: '2021-04-23T04:03:04.010Z',
                  updated: '2021-04-23T04:03:04.010Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              bpMeasurements: [
                {
                  id: 5,
                  created: '2021-04-23T04:19:47.819Z',
                  updated: '2021-04-23T04:19:47.000Z',
                  measured: '2020-04-13T06:59:40.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X3',
                  deviceTime: '2020-04-13T06:59:40.000Z',
                  diastolic: 87,
                  systolic: 180,
                  pulse: 73,
                  afib: false,
                  arr: true,
                  pc: true,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                },
              ],
              oxygenSatMeasurements: [
                {
                  id: 5,
                  created: '2021-04-23T04:19:47.832Z',
                  updated: '2021-04-23T04:19:47.000Z',
                  measured: '2020-04-13T07:17:24.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SA300',
                  deviceTime: '2020-04-13T07:17:24.000Z',
                  actHighest: 2,
                  pulseHighest: 83,
                  pulseLowest: 83,
                  spo2Highest: 97,
                  spo2Lowest: 97,
                },
              ],
              glucoseMeasurements: [
                {
                  id: 5,
                  created: '2021-04-23T04:19:47.837Z',
                  updated: '2021-04-23T04:19:47.000Z',
                  measured: '2020-04-13T07:08:06.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2020-04-13T07:08:06.000Z',
                  bloodGlucose: 90,
                  meal: 'Fasting',
                },
              ],
              bodyTempMeasurements: [
                {
                  id: 5,
                  created: '2021-04-23T04:19:47.839Z',
                  updated: '2021-04-23T04:19:47.000Z',
                  measured: '2020-04-13T06:56:44.000Z',
                  isNormal: true,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2020-04-13T06:56:44.000Z',
                  bodyTemperature: 36.5,
                },
              ],
              bodyWeightFatMeasurements: [
                {
                  id: 5,
                  created: '2021-04-23T04:19:47.828Z',
                  updated: '2021-04-23T04:19:47.000Z',
                  measured: '2020-04-13T07:22:32.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2020-04-13T07:22:32.000Z',
                  weight: 51.6,
                  bmi: 22.6378,
                  bmr: 1271.18,
                  bodyFat: 25.7948,
                  bodyWater: 54.3524,
                  muscleMass: 16.8697,
                  visceralFat: 5,
                },
              ],
              location: {
                id: 2,
                created: '2021-04-13T00:40:16.023Z',
                updated: '2021-04-13T00:40:16.023Z',
                code: '265',
                title: '2327清水中山三店',
                staffs: [],
              },
            },
            {
              id: 19,
              created: '2021-04-23T04:03:34.511Z',
              updated: '2021-04-23T04:03:34.000Z',
              hubCode: 'YC001',
              subject: {
                id: 20,
                created: '2021-04-14T04:11:33.332Z',
                updated: '2021-04-26T07:04:14.000Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                hasOwnedCard: false,
                enjoyment: {
                  id: 1,
                  created: '2021-04-26T07:04:14.337Z',
                  updated: '2021-04-26T07:04:14.337Z',
                  __belongsToContracts__: [
                    {
                      id: 3,
                      created: '2021-04-26T07:08:03.027Z',
                      updated: '2021-04-26T07:08:03.027Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 2,
                      created: '2021-04-26T07:06:48.750Z',
                      updated: '2021-04-26T07:06:48.750Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                    {
                      id: 1,
                      created: '2021-04-26T07:04:14.401Z',
                      updated: '2021-04-26T07:04:14.401Z',
                      startTime: '2020-08-10T16:00:00.000Z',
                      endTime: '2020-11-10T16:00:00.000Z',
                      activationCode: 'GWJTG41980',
                      state: 0,
                    },
                  ],
                },
                cache: {
                  id: 105,
                  created: '2021-04-23T04:03:04.010Z',
                  updated: '2021-04-23T04:03:04.010Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              bpMeasurements: [
                {
                  id: 4,
                  created: '2021-04-23T04:03:34.509Z',
                  updated: '2021-04-23T04:03:34.000Z',
                  measured: '2020-04-13T06:59:40.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X3',
                  deviceTime: '2020-04-13T06:59:40.000Z',
                  diastolic: 87,
                  systolic: 136,
                  pulse: 73,
                  afib: false,
                  arr: true,
                  pc: true,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                },
              ],
              oxygenSatMeasurements: [
                {
                  id: 4,
                  created: '2021-04-23T04:03:34.515Z',
                  updated: '2021-04-23T04:03:34.000Z',
                  measured: '2020-04-13T07:17:24.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SA300',
                  deviceTime: '2020-04-13T07:17:24.000Z',
                  actHighest: 2,
                  pulseHighest: 83,
                  pulseLowest: 83,
                  spo2Highest: 97,
                  spo2Lowest: 97,
                },
              ],
              glucoseMeasurements: [
                {
                  id: 4,
                  created: '2021-04-23T04:03:34.522Z',
                  updated: '2021-04-23T04:03:34.000Z',
                  measured: '2020-04-13T07:08:06.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2020-04-13T07:08:06.000Z',
                  bloodGlucose: 90,
                  meal: 'Fasting',
                },
              ],
              bodyTempMeasurements: [
                {
                  id: 4,
                  created: '2021-04-23T04:03:34.524Z',
                  updated: '2021-04-23T04:03:34.000Z',
                  measured: '2020-04-13T06:56:44.000Z',
                  isNormal: true,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2020-04-13T06:56:44.000Z',
                  bodyTemperature: 36.5,
                },
              ],
              bodyWeightFatMeasurements: [
                {
                  id: 4,
                  created: '2021-04-23T04:03:34.522Z',
                  updated: '2021-04-23T04:03:34.000Z',
                  measured: '2020-04-13T07:22:32.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2020-04-13T07:22:32.000Z',
                  weight: 51.6,
                  bmi: 22.6378,
                  bmr: 1271.18,
                  bodyFat: 25.7948,
                  bodyWater: 54.3524,
                  muscleMass: 16.8697,
                  visceralFat: 5,
                },
              ],
              location: {
                id: 2,
                created: '2021-04-13T00:40:16.023Z',
                updated: '2021-04-13T00:40:16.023Z',
                code: '265',
                title: '2327清水中山三店',
                staffs: [],
              },
            },
          ],
        }
      } else {
        return {
          data: {
            bpMeasurements: [
              {
                measurement: {
                  id: 37,
                  created: '2021-04-25T13:22:06.073Z',
                  updated: '2021-04-25T13:22:06.000Z',
                  measured: '2021-04-30T' + mock('@time') + 'Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-29T06:11:04.000Z',
                  diastolic: 86,
                  systolic: 135,
                  pulse: mock('@integer(60, 120)'),
                  afib: true,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                  usageRecordID: 37,
                  uploadTime: '2021-04-25T13:22:06.073Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 32,
                  created: '2021-04-25T13:22:05.132Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-14T' + mock('@time') + 'Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-14T06:04:56.000Z',
                  diastolic: 89,
                  systolic: 133,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 44,
                  scene: null,
                  usageRecordID: 32,
                  uploadTime: '2021-04-25T13:22:05.132Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 34,
                  created: '2021-04-25T13:22:05.499Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-19T' + mock('@time') + 'Z',
                  isNormal: false,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-20T06:23:22.000Z',
                  diastolic: 89,
                  systolic: 133,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 44,
                  scene: null,
                  usageRecordID: 34,
                  uploadTime: '2021-04-25T13:22:05.499Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 33,
                  created: '2021-04-25T13:22:05.298Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-17T' + mock('@time') + 'Z',
                  isNormal: false,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-17T06:39:58.000Z',
                  diastolic: 87,
                  systolic: 189,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: true,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 102,
                  scene: null,
                  usageRecordID: 33,
                  uploadTime: '2021-04-25T13:22:05.297Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 36,
                  created: '2021-04-25T13:22:05.895Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-26T06:32:12.000Z',
                  isNormal: false,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-26T06:32:12.000Z',
                  diastolic: 86,
                  systolic: 134,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 48,
                  scene: null,
                  usageRecordID: 36,
                  uploadTime: '2021-04-25T13:22:05.894Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 35,
                  created: '2021-04-25T13:22:05.678Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-23T06:10:33.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-23T06:10:33.000Z',
                  diastolic: 87,
                  systolic: 132,
                  pulse: mock('@integer(60, 120)'),
                  afib: true,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 45,
                  scene: null,
                  usageRecordID: 35,
                  uploadTime: '2021-04-25T13:22:05.678Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 31,
                  created: '2021-04-25T13:22:04.955Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-11T06:43:41.000Z',
                  isNormal: false,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-11T06:43:41.000Z',
                  diastolic: 85,
                  systolic: 150,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 65,
                  scene: null,
                  usageRecordID: 31,
                  uploadTime: '2021-04-25T13:22:04.964Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 30,
                  created: '2021-04-25T13:22:04.795Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-08T06:41:19.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-08T06:41:19.000Z',
                  diastolic: 89,
                  systolic: 136,
                  pulse: mock('@integer(60, 120)'),
                  afib: true,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 47,
                  scene: null,
                  usageRecordID: 30,
                  uploadTime: '2021-04-25T13:22:04.791Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 29,
                  created: '2021-04-25T13:22:04.629Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-05T06:47:58.000Z',
                  isNormal: true,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-05T06:47:58.000Z',
                  diastolic: 86,
                  systolic: 135,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: false,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 49,
                  scene: null,
                  usageRecordID: 29,
                  uploadTime: '2021-04-25T13:22:04.628Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 28,
                  created: '2021-04-25T13:22:04.436Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-02T06:49:27.000Z',
                  isNormal: false,
                  deviceID: 'RM_BP_X5',
                  deviceTime: '2021-04-02T06:49:27.000Z',
                  diastolic: 86,
                  systolic: 139,
                  pulse: mock('@integer(60, 120)'),
                  afib: false,
                  arr: true,
                  pc: false,
                  ihb: false,
                  pulsePressureDifference: 53,
                  scene: null,
                  usageRecordID: 28,
                  uploadTime: '2021-04-25T13:22:04.436Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
            ],
            oxygenSatMeasurements: [
              {
                measurement: {
                  id: 37,
                  created: '2021-04-25T13:22:06.073Z',
                  updated: '2021-04-25T13:22:06.000Z',
                  measured: '2021-04-29T06:11:04.000Z',
                  isNormal: false,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-29T06:11:04.000Z',
                  actHighest: 4,
                  pulseHighest: 78,
                  pulseLowest: 72,
                  spo2Highest: 100,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 37,
                  uploadTime: '2021-04-25T13:22:06.073Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 32,
                  created: '2021-04-25T13:22:05.131Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-14T06:04:56.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-14T06:04:56.000Z',
                  actHighest: 3,
                  pulseHighest: 78,
                  pulseLowest: 73,
                  spo2Highest: 99,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 32,
                  uploadTime: '2021-04-25T13:22:05.132Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 34,
                  created: '2021-04-25T13:22:05.500Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-20T06:23:22.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-20T06:23:22.000Z',
                  actHighest: 3,
                  pulseHighest: 79,
                  pulseLowest: 75,
                  spo2Highest: 99,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 34,
                  uploadTime: '2021-04-25T13:22:05.499Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 33,
                  created: '2021-04-25T13:22:05.298Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-17T06:39:58.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-17T06:39:58.000Z',
                  actHighest: 4,
                  pulseHighest: 79,
                  pulseLowest: 73,
                  spo2Highest: 100,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 33,
                  uploadTime: '2021-04-25T13:22:05.297Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 36,
                  created: '2021-04-25T13:22:05.895Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-26T06:32:12.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-26T06:32:12.000Z',
                  actHighest: 4,
                  pulseHighest: 78,
                  pulseLowest: 74,
                  spo2Highest: 100,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 36,
                  uploadTime: '2021-04-25T13:22:05.894Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 35,
                  created: '2021-04-25T13:22:05.680Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-23T06:10:33.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-23T06:10:33.000Z',
                  actHighest: 3,
                  pulseHighest: 77,
                  pulseLowest: 70,
                  spo2Highest: 99,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 35,
                  uploadTime: '2021-04-25T13:22:05.678Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 31,
                  created: '2021-04-25T13:22:04.954Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-11T06:43:41.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-11T06:43:41.000Z',
                  actHighest: 4,
                  pulseHighest: 77,
                  pulseLowest: 70,
                  spo2Highest: 98,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 31,
                  uploadTime: '2021-04-25T13:22:04.964Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 30,
                  created: '2021-04-25T13:22:04.794Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-08T06:41:19.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-08T06:41:19.000Z',
                  actHighest: 3,
                  pulseHighest: 79,
                  pulseLowest: 70,
                  spo2Highest: 100,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 30,
                  uploadTime: '2021-04-25T13:22:04.791Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 29,
                  created: '2021-04-25T13:22:04.629Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-05T06:47:58.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-05T06:47:58.000Z',
                  actHighest: 3,
                  pulseHighest: 80,
                  pulseLowest: 70,
                  spo2Highest: 98,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 29,
                  uploadTime: '2021-04-25T13:22:04.628Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 28,
                  created: '2021-04-25T13:22:04.438Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-02T06:49:27.000Z',
                  isNormal: true,
                  deviceID: 'RM_PO_SB210',
                  deviceTime: '2021-04-02T06:49:27.000Z',
                  actHighest: 3,
                  pulseHighest: 76,
                  pulseLowest: 72,
                  spo2Highest: 99,
                  spo2Lowest: mock('@integer(85, 100)'),
                  usageRecordID: 28,
                  uploadTime: '2021-04-25T13:22:04.436Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
            ],
            glucoseMeasurements: [
              {
                measurement: {
                  id: 37,
                  created: '2021-04-25T13:22:06.074Z',
                  updated: '2021-04-25T13:22:06.000Z',
                  measured: '2021-04-29T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-29T06:11:04.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 37,
                  uploadTime: '2021-04-25T13:22:06.073Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 32,
                  created: '2021-04-25T13:22:05.133Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-14T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-14T06:04:56.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 32,
                  uploadTime: '2021-04-25T13:22:05.132Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 34,
                  created: '2021-04-25T13:22:05.500Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-20T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-20T06:23:22.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 34,
                  uploadTime: '2021-04-25T13:22:05.499Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 33,
                  created: '2021-04-25T13:22:05.297Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-17T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-17T06:39:58.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 33,
                  uploadTime: '2021-04-25T13:22:05.297Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 36,
                  created: '2021-04-25T13:22:05.896Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-26T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-26T06:32:12.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 36,
                  uploadTime: '2021-04-25T13:22:05.894Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 35,
                  created: '2021-04-25T13:22:05.679Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-23T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-23T06:10:33.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 35,
                  uploadTime: '2021-04-25T13:22:05.678Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 31,
                  created: '2021-04-25T13:22:04.954Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-11T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-11T06:43:41.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 31,
                  uploadTime: '2021-04-25T13:22:04.964Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 30,
                  created: '2021-04-25T13:22:04.795Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-08T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-08T06:41:19.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 30,
                  uploadTime: '2021-04-25T13:22:04.791Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 29,
                  created: '2021-04-25T13:22:04.630Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-05T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-05T06:47:58.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 29,
                  uploadTime: '2021-04-25T13:22:04.628Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 28,
                  created: '2021-04-25T13:22:04.437Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-02T' + mock('@time') + '.000Z',
                  isNormal: true,
                  deviceID: 'RM_BG_HT100',
                  deviceTime: '2021-04-02T06:49:27.000Z',
                  bloodGlucose: mock('@integer(89, 213)'),
                  meal: mock({
                    'string|1': ['Fasting', 'Preprandial', 'Postprandial'],
                  }).string,
                  usageRecordID: 28,
                  uploadTime: '2021-04-25T13:22:04.436Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
            ],
            bodyWeightFatMeasurements: [
              {
                measurement: {
                  id: 37,
                  created: '2021-04-25T13:22:06.072Z',
                  updated: '2021-04-25T13:22:06.000Z',
                  measured: '2021-04-29T06:11:04.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-29T06:11:04.000Z',
                  weight: 67.7376,
                  bmi: mock('@float(16, 35)'),
                  bmr: 760.801,
                  bodyFat: 16.7,
                  bodyWater: 40.059,
                  muscleMass: 28.4498,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 37,
                  uploadTime: '2021-04-25T13:22:06.073Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 32,
                  created: '2021-04-25T13:22:05.133Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-14T06:04:56.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-14T06:04:56.000Z',
                  weight: 69.1488,
                  bmi: mock('@float(16, 35)'),
                  bmr: 762.734,
                  bodyFat: 18,
                  bodyWater: 40.5334,
                  muscleMass: 28.351,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 32,
                  uploadTime: '2021-04-25T13:22:05.132Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 34,
                  created: '2021-04-25T13:22:05.499Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-20T06:23:22.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-20T06:23:22.000Z',
                  weight: 68.5843,
                  bmi: mock('@float(16, 35)'),
                  bmr: 761.961,
                  bodyFat: 18,
                  bodyWater: 40.3436,
                  muscleMass: 28.1196,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 34,
                  uploadTime: '2021-04-25T13:22:05.499Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 33,
                  created: '2021-04-25T13:22:05.298Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-17T06:39:58.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-17T06:39:58.000Z',
                  weight: 70.2778,
                  bmi: mock('@float(16, 35)'),
                  bmr: 764.281,
                  bodyFat: 16.9,
                  bodyWater: 40.913,
                  muscleMass: 28.8139,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 33,
                  uploadTime: '2021-04-25T13:22:05.297Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 36,
                  created: '2021-04-25T13:22:05.906Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-26T06:32:12.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-26T06:32:12.000Z',
                  weight: 69.1488,
                  bmi: mock('@float(16, 35)'),
                  bmr: 762.734,
                  bodyFat: 16.2,
                  bodyWater: 40.5334,
                  muscleMass: 29.0425,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 36,
                  uploadTime: '2021-04-25T13:22:05.894Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 35,
                  created: '2021-04-25T13:22:05.679Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-23T06:10:33.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-23T06:10:33.000Z',
                  weight: 69.9955,
                  bmi: mock('@float(16, 35)'),
                  bmr: 763.894,
                  bodyFat: 17.9,
                  bodyWater: 40.8181,
                  muscleMass: 28.6982,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 35,
                  uploadTime: '2021-04-25T13:22:05.678Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 31,
                  created: '2021-04-25T13:22:04.964Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-11T06:43:41.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-11T06:43:41.000Z',
                  weight: 67.7376,
                  bmi: mock('@float(16, 35)'),
                  bmr: 760.801,
                  bodyFat: 17.3,
                  bodyWater: 40.059,
                  muscleMass: 28.4498,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 31,
                  uploadTime: '2021-04-25T13:22:04.964Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 30,
                  created: '2021-04-25T13:22:04.795Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-08T06:41:19.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-08T06:41:19.000Z',
                  weight: 69.431,
                  bmi: mock('@float(16, 35)'),
                  bmr: 763.121,
                  bodyFat: 17.6,
                  bodyWater: 40.6283,
                  muscleMass: 27.7724,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 30,
                  uploadTime: '2021-04-25T13:22:04.791Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 29,
                  created: '2021-04-25T13:22:04.630Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-05T06:47:58.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-05T06:47:58.000Z',
                  weight: 68.8666,
                  bmi: mock('@float(16, 35)'),
                  bmr: 762.347,
                  bodyFat: 16.8,
                  bodyWater: 40.4385,
                  muscleMass: 28.2353,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 29,
                  uploadTime: '2021-04-25T13:22:04.628Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 28,
                  created: '2021-04-25T13:22:04.437Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-02T06:49:27.000Z',
                  isNormal: true,
                  deviceID: 'RM_BF_LS212-B',
                  deviceTime: '2021-04-02T06:49:27.000Z',
                  weight: 69.7133,
                  bmi: mock('@float(16, 35)'),
                  bmr: 763.507,
                  bodyFat: 16.9,
                  bodyWater: 40.7232,
                  muscleMass: 29.2796,
                  visceralFat: mock('@integer(1, 30)'),
                  usageRecordID: 28,
                  uploadTime: '2021-04-25T13:22:04.436Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
            ],
            bodyTempMeasurements: [
              {
                measurement: {
                  id: 37,
                  created: '2021-04-25T13:22:06.073Z',
                  updated: '2021-04-25T13:22:06.000Z',
                  measured: '2021-04-29T06:11:04.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-29T06:11:04.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 37,
                  uploadTime: '2021-04-25T13:22:06.073Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 32,
                  created: '2021-04-25T13:22:05.134Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-14T06:04:56.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-14T06:04:56.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 32,
                  uploadTime: '2021-04-25T13:22:05.132Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 34,
                  created: '2021-04-25T13:22:05.500Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-20T06:23:22.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-20T06:23:22.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 34,
                  uploadTime: '2021-04-25T13:22:05.499Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 33,
                  created: '2021-04-25T13:22:05.299Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-17T06:39:58.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-17T06:39:58.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 33,
                  uploadTime: '2021-04-25T13:22:05.297Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 36,
                  created: '2021-04-25T13:22:05.895Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-26T06:32:12.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-26T06:32:12.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 36,
                  uploadTime: '2021-04-25T13:22:05.894Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 35,
                  created: '2021-04-25T13:22:05.680Z',
                  updated: '2021-04-25T13:22:05.000Z',
                  measured: '2021-04-23T06:10:33.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-23T06:10:33.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 35,
                  uploadTime: '2021-04-25T13:22:05.678Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 31,
                  created: '2021-04-25T13:22:04.954Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-11T06:43:41.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-11T06:43:41.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 31,
                  uploadTime: '2021-04-25T13:22:04.964Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 30,
                  created: '2021-04-25T13:22:04.791Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-08T06:41:19.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-08T06:41:19.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 30,
                  uploadTime: '2021-04-25T13:22:04.791Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 29,
                  created: '2021-04-25T13:22:04.629Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-05T06:47:58.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-05T06:47:58.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 29,
                  uploadTime: '2021-04-25T13:22:04.628Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
              {
                measurement: {
                  id: 28,
                  created: '2021-04-25T13:22:04.438Z',
                  updated: '2021-04-25T13:22:04.000Z',
                  measured: '2021-04-02T06:49:27.000Z',
                  isNormal: false,
                  deviceID: 'RM_TM_HC700',
                  deviceTime: '2021-04-02T06:49:27.000Z',
                  bodyTemperature: mock('@float(35, 39)'),
                  usageRecordID: 28,
                  uploadTime: '2021-04-25T13:22:04.436Z',
                },
                member: {
                  id: 2,
                  created: '2021-04-25T13:22:04.280Z',
                  updated: '2021-04-25T13:22:04.280Z',
                  cardNumber: '0100000420',
                  cardHiddenCode: '500',
                  memberID: '1511001',
                  name: '案例一',
                  level: '一般會員',
                },
              },
            ],
          },
        }
      }
    },
  },
  // 取得指定姓名與區域範圍與時間範圍的會員量測資料
  {
    url: '/measurements/areas/\\d{1,3}-\\d{1,3}/member',
    type: 'get',
    response() {
      return {
        data: [
          {
            id: 22,
            created: '2021-04-27T11:53:31.793Z',
            updated: '2021-04-27T11:53:31.000Z',
            hubCode: 'YC001',
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              enjoyment: {
                id: 1,
                created: '2021-04-26T07:04:14.337Z',
                updated: '2021-04-26T07:04:14.337Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-04-26T07:08:03.027Z',
                    updated: '2021-04-26T07:08:03.027Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-04-26T07:06:48.750Z',
                    updated: '2021-04-26T07:06:48.750Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-04-26T07:04:14.401Z',
                    updated: '2021-04-26T07:04:14.401Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 7,
                created: '2021-04-27T11:53:31.790Z',
                updated: '2021-04-27T11:53:31.000Z',
                measured: '2020-04-13T06:59:40.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X3',
                deviceTime: '2020-04-13T06:59:40.000Z',
                diastolic: 87,
                systolic: 181,
                pulse: mock('@integer(60, 120)'),
                afib: false,
                arr: true,
                pc: true,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 7,
                created: '2021-04-27T11:53:31.793Z',
                updated: '2021-04-27T11:53:31.000Z',
                measured: '2020-04-13T07:17:24.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SA300',
                deviceTime: '2020-04-13T07:17:24.000Z',
                actHighest: 2,
                pulseHighest: 83,
                pulseLowest: 83,
                spo2Highest: 97,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 7,
                created: '2021-04-27T11:53:31.793Z',
                updated: '2021-04-27T11:53:31.000Z',
                measured: '2020-04-13T07:08:06.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2020-04-13T07:08:06.000Z',
                bloodGlucose: 90,
                meal: 'Fasting',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 7,
                created: '2021-04-27T11:53:31.793Z',
                updated: '2021-04-27T11:53:31.000Z',
                measured: '2020-04-13T06:56:44.000Z',
                isNormal: true,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2020-04-13T06:56:44.000Z',
                bodyTemperature: 36.5,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 7,
                created: '2021-04-27T11:53:31.793Z',
                updated: '2021-04-27T11:53:31.000Z',
                measured: '2020-04-13T07:22:32.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2020-04-13T07:22:32.000Z',
                weight: 51.6,
                bmi: 22.6378,
                bmr: 1271.18,
                bodyFat: 25.7948,
                bodyWater: 54.3524,
                muscleMass: 16.8697,
                visceralFat: 5,
              },
            ],
            location: {
              id: 2,
              created: '2021-04-13T00:40:16.023Z',
              updated: '2021-04-13T00:40:16.023Z',
              code: '265',
              title: '2327清水中山三店',
              staffs: [],
            },
          },
          {
            id: 21,
            created: '2021-04-27T11:53:10.410Z',
            updated: '2021-04-27T11:53:10.000Z',
            hubCode: 'YC001',
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              enjoyment: {
                id: 1,
                created: '2021-04-26T07:04:14.337Z',
                updated: '2021-04-26T07:04:14.337Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-04-26T07:08:03.027Z',
                    updated: '2021-04-26T07:08:03.027Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-04-26T07:06:48.750Z',
                    updated: '2021-04-26T07:06:48.750Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-04-26T07:04:14.401Z',
                    updated: '2021-04-26T07:04:14.401Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 6,
                created: '2021-04-27T11:53:10.564Z',
                updated: '2021-04-27T11:53:10.000Z',
                measured: '2020-04-13T06:59:40.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X3',
                deviceTime: '2020-04-13T06:59:40.000Z',
                diastolic: 87,
                systolic: 136,
                pulse: 73,
                afib: false,
                arr: true,
                pc: true,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 6,
                created: '2021-04-27T11:53:10.566Z',
                updated: '2021-04-27T11:53:10.000Z',
                measured: '2020-04-13T07:17:24.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SA300',
                deviceTime: '2020-04-13T07:17:24.000Z',
                actHighest: 2,
                pulseHighest: 83,
                pulseLowest: 83,
                spo2Highest: 97,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 6,
                created: '2021-04-27T11:53:10.671Z',
                updated: '2021-04-27T11:53:10.000Z',
                measured: '2020-04-13T07:08:06.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2020-04-13T07:08:06.000Z',
                bloodGlucose: 90,
                meal: 'Fasting',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 6,
                created: '2021-04-27T11:53:10.577Z',
                updated: '2021-04-27T11:53:10.000Z',
                measured: '2020-04-13T06:56:44.000Z',
                isNormal: true,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2020-04-13T06:56:44.000Z',
                bodyTemperature: 36.5,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 6,
                created: '2021-04-27T11:53:10.663Z',
                updated: '2021-04-27T11:53:10.000Z',
                measured: '2020-04-13T07:22:32.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2020-04-13T07:22:32.000Z',
                weight: 51.6,
                bmi: 22.6378,
                bmr: 1271.18,
                bodyFat: 25.7948,
                bodyWater: 54.3524,
                muscleMass: 16.8697,
                visceralFat: 5,
              },
            ],
            location: {
              id: 2,
              created: '2021-04-13T00:40:16.023Z',
              updated: '2021-04-13T00:40:16.023Z',
              code: '265',
              title: '2327清水中山三店',
              staffs: [],
            },
          },
          {
            id: 20,
            created: '2021-04-23T04:19:47.820Z',
            updated: '2021-04-23T04:19:47.000Z',
            hubCode: 'YC001',
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              enjoyment: {
                id: 1,
                created: '2021-04-26T07:04:14.337Z',
                updated: '2021-04-26T07:04:14.337Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-04-26T07:08:03.027Z',
                    updated: '2021-04-26T07:08:03.027Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-04-26T07:06:48.750Z',
                    updated: '2021-04-26T07:06:48.750Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-04-26T07:04:14.401Z',
                    updated: '2021-04-26T07:04:14.401Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 5,
                created: '2021-04-23T04:19:47.819Z',
                updated: '2021-04-23T04:19:47.000Z',
                measured: '2020-04-13T06:59:40.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X3',
                deviceTime: '2020-04-13T06:59:40.000Z',
                diastolic: 87,
                systolic: 180,
                pulse: 73,
                afib: false,
                arr: true,
                pc: true,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 5,
                created: '2021-04-23T04:19:47.832Z',
                updated: '2021-04-23T04:19:47.000Z',
                measured: '2020-04-13T07:17:24.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SA300',
                deviceTime: '2020-04-13T07:17:24.000Z',
                actHighest: 2,
                pulseHighest: 83,
                pulseLowest: 83,
                spo2Highest: 97,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 5,
                created: '2021-04-23T04:19:47.837Z',
                updated: '2021-04-23T04:19:47.000Z',
                measured: '2020-04-13T07:08:06.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2020-04-13T07:08:06.000Z',
                bloodGlucose: 90,
                meal: 'Fasting',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 5,
                created: '2021-04-23T04:19:47.839Z',
                updated: '2021-04-23T04:19:47.000Z',
                measured: '2020-04-13T06:56:44.000Z',
                isNormal: true,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2020-04-13T06:56:44.000Z',
                bodyTemperature: 36.5,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 5,
                created: '2021-04-23T04:19:47.828Z',
                updated: '2021-04-23T04:19:47.000Z',
                measured: '2020-04-13T07:22:32.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2020-04-13T07:22:32.000Z',
                weight: 51.6,
                bmi: 22.6378,
                bmr: 1271.18,
                bodyFat: 25.7948,
                bodyWater: 54.3524,
                muscleMass: 16.8697,
                visceralFat: 5,
              },
            ],
            location: {
              id: 2,
              created: '2021-04-13T00:40:16.023Z',
              updated: '2021-04-13T00:40:16.023Z',
              code: '265',
              title: '2327清水中山三店',
              staffs: [],
            },
          },
          {
            id: 19,
            created: '2021-04-23T04:03:34.511Z',
            updated: '2021-04-23T04:03:34.000Z',
            hubCode: 'YC001',
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              enjoyment: {
                id: 1,
                created: '2021-04-26T07:04:14.337Z',
                updated: '2021-04-26T07:04:14.337Z',
                __belongsToContracts__: [
                  {
                    id: 3,
                    created: '2021-04-26T07:08:03.027Z',
                    updated: '2021-04-26T07:08:03.027Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 2,
                    created: '2021-04-26T07:06:48.750Z',
                    updated: '2021-04-26T07:06:48.750Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                  {
                    id: 1,
                    created: '2021-04-26T07:04:14.401Z',
                    updated: '2021-04-26T07:04:14.401Z',
                    startTime: '2020-08-10T16:00:00.000Z',
                    endTime: '2020-11-10T16:00:00.000Z',
                    activationCode: 'GWJTG41980',
                    state: 0,
                  },
                ],
              },
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            bpMeasurements: [
              {
                id: 4,
                created: '2021-04-23T04:03:34.509Z',
                updated: '2021-04-23T04:03:34.000Z',
                measured: '2020-04-13T06:59:40.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X3',
                deviceTime: '2020-04-13T06:59:40.000Z',
                diastolic: 87,
                systolic: 136,
                pulse: 73,
                afib: false,
                arr: true,
                pc: true,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
              },
            ],
            oxygenSatMeasurements: [
              {
                id: 4,
                created: '2021-04-23T04:03:34.515Z',
                updated: '2021-04-23T04:03:34.000Z',
                measured: '2020-04-13T07:17:24.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SA300',
                deviceTime: '2020-04-13T07:17:24.000Z',
                actHighest: 2,
                pulseHighest: 83,
                pulseLowest: 83,
                spo2Highest: 97,
                spo2Lowest: 97,
              },
            ],
            glucoseMeasurements: [
              {
                id: 4,
                created: '2021-04-23T04:03:34.522Z',
                updated: '2021-04-23T04:03:34.000Z',
                measured: '2020-04-13T07:08:06.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2020-04-13T07:08:06.000Z',
                bloodGlucose: 90,
                meal: 'Fasting',
              },
            ],
            bodyTempMeasurements: [
              {
                id: 4,
                created: '2021-04-23T04:03:34.524Z',
                updated: '2021-04-23T04:03:34.000Z',
                measured: '2020-04-13T06:56:44.000Z',
                isNormal: true,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2020-04-13T06:56:44.000Z',
                bodyTemperature: 36.5,
              },
            ],
            bodyWeightFatMeasurements: [
              {
                id: 4,
                created: '2021-04-23T04:03:34.522Z',
                updated: '2021-04-23T04:03:34.000Z',
                measured: '2020-04-13T07:22:32.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2020-04-13T07:22:32.000Z',
                weight: 51.6,
                bmi: 22.6378,
                bmr: 1271.18,
                bodyFat: 25.7948,
                bodyWater: 54.3524,
                muscleMass: 16.8697,
                visceralFat: 5,
              },
            ],
            location: {
              id: 2,
              created: '2021-04-13T00:40:16.023Z',
              updated: '2021-04-13T00:40:16.023Z',
              code: '265',
              title: '2327清水中山三店',
              staffs: [],
            },
          },
        ],
      }
    },
  },
  // 以會員編號和時間區域取得量測資料
  {
    url: '/measurements/member/\\d+/timeRange/\\d+/\\d+',
    type: 'get',
    response() {
      return {
        data: {
          bpMeasurements: [
            {
              measurement: {
                id: 28,
                created: '2021-04-25T13:22:04.436Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-02T06:49:27.000Z',
                diastolic: 86,
                systolic: 139,
                pulse: 72,
                afib: false,
                arr: true,
                pc: false,
                ihb: false,
                pulsePressureDifference: 53,
                scene: null,
                usageRecordID: 28,
                uploadTime: '2021-04-25T13:22:04.436Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-05T06:47:58.000Z',
                diastolic: 86,
                systolic: 135,
                pulse: 74,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
                usageRecordID: 29,
                uploadTime: '2021-04-25T13:22:04.628Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-08T06:41:19.000Z',
                diastolic: 89,
                systolic: 136,
                pulse: 73,
                afib: true,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 47,
                scene: null,
                usageRecordID: 30,
                uploadTime: '2021-04-25T13:22:04.791Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 31,
                created: '2021-04-25T13:22:04.955Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-11T06:43:41.000Z',
                diastolic: 85,
                systolic: 134,
                pulse: 71,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
                usageRecordID: 31,
                uploadTime: '2021-04-25T13:22:04.964Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 32,
                created: '2021-04-25T13:22:05.132Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-14T06:04:56.000Z',
                diastolic: 89,
                systolic: 133,
                pulse: 72,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 44,
                scene: null,
                usageRecordID: 32,
                uploadTime: '2021-04-25T13:22:05.132Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-17T06:39:58.000Z',
                diastolic: 87,
                systolic: 138,
                pulse: 70,
                afib: false,
                arr: true,
                pc: false,
                ihb: false,
                pulsePressureDifference: 51,
                scene: null,
                usageRecordID: 33,
                uploadTime: '2021-04-25T13:22:05.297Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 34,
                created: '2021-04-25T13:22:05.499Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-20T06:23:22.000Z',
                diastolic: 89,
                systolic: 133,
                pulse: 71,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 44,
                scene: null,
                usageRecordID: 34,
                uploadTime: '2021-04-25T13:22:05.499Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 35,
                created: '2021-04-25T13:22:05.678Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-23T06:10:33.000Z',
                diastolic: 87,
                systolic: 132,
                pulse: 74,
                afib: true,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 45,
                scene: null,
                usageRecordID: 35,
                uploadTime: '2021-04-25T13:22:05.678Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-26T06:32:12.000Z',
                diastolic: 86,
                systolic: 134,
                pulse: 79,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 48,
                scene: null,
                usageRecordID: 36,
                uploadTime: '2021-04-25T13:22:05.894Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BP_X5',
                deviceTime: '2021-04-29T06:11:04.000Z',
                diastolic: 86,
                systolic: 135,
                pulse: 73,
                afib: false,
                arr: false,
                pc: false,
                ihb: false,
                pulsePressureDifference: 49,
                scene: null,
                usageRecordID: 37,
                uploadTime: '2021-04-25T13:22:06.073Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          ],
          oxygenSatMeasurements: [
            {
              measurement: {
                id: 28,
                created: '2021-04-25T13:22:04.438Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-02T06:49:27.000Z',
                actHighest: 3,
                pulseHighest: 76,
                pulseLowest: 72,
                spo2Highest: 99,
                spo2Lowest: 98,
                usageRecordID: 28,
                uploadTime: '2021-04-25T13:22:04.436Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-05T06:47:58.000Z',
                actHighest: 3,
                pulseHighest: 80,
                pulseLowest: 70,
                spo2Highest: 98,
                spo2Lowest: 97,
                usageRecordID: 29,
                uploadTime: '2021-04-25T13:22:04.628Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 30,
                created: '2021-04-25T13:22:04.794Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-08T06:41:19.000Z',
                actHighest: 3,
                pulseHighest: 79,
                pulseLowest: 70,
                spo2Highest: 100,
                spo2Lowest: 97,
                usageRecordID: 30,
                uploadTime: '2021-04-25T13:22:04.791Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-11T06:43:41.000Z',
                actHighest: 4,
                pulseHighest: 77,
                pulseLowest: 70,
                spo2Highest: 98,
                spo2Lowest: 97,
                usageRecordID: 31,
                uploadTime: '2021-04-25T13:22:04.964Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 32,
                created: '2021-04-25T13:22:05.131Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-14T06:04:56.000Z',
                actHighest: 3,
                pulseHighest: 78,
                pulseLowest: 73,
                spo2Highest: 99,
                spo2Lowest: 97,
                usageRecordID: 32,
                uploadTime: '2021-04-25T13:22:05.132Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-17T06:39:58.000Z',
                actHighest: 4,
                pulseHighest: 79,
                pulseLowest: 73,
                spo2Highest: 100,
                spo2Lowest: 97,
                usageRecordID: 33,
                uploadTime: '2021-04-25T13:22:05.297Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-20T06:23:22.000Z',
                actHighest: 3,
                pulseHighest: 79,
                pulseLowest: 75,
                spo2Highest: 99,
                spo2Lowest: 97,
                usageRecordID: 34,
                uploadTime: '2021-04-25T13:22:05.499Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 35,
                created: '2021-04-25T13:22:05.680Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-23T06:10:33.000Z',
                actHighest: 3,
                pulseHighest: 77,
                pulseLowest: 70,
                spo2Highest: 99,
                spo2Lowest: 98,
                usageRecordID: 35,
                uploadTime: '2021-04-25T13:22:05.678Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-26T06:32:12.000Z',
                actHighest: 4,
                pulseHighest: 78,
                pulseLowest: 74,
                spo2Highest: 100,
                spo2Lowest: 98,
                usageRecordID: 36,
                uploadTime: '2021-04-25T13:22:05.894Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_PO_SB210',
                deviceTime: '2021-04-29T06:11:04.000Z',
                actHighest: 4,
                pulseHighest: 78,
                pulseLowest: 72,
                spo2Highest: 100,
                spo2Lowest: 97,
                usageRecordID: 37,
                uploadTime: '2021-04-25T13:22:06.073Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          ],
          glucoseMeasurements: [
            {
              measurement: {
                id: 28,
                created: '2021-04-25T13:22:04.437Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-02T06:49:27.000Z',
                bloodGlucose: 138,
                meal: 'Postprandial',
                usageRecordID: 28,
                uploadTime: '2021-04-25T13:22:04.436Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 29,
                created: '2021-04-25T13:22:04.630Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-05T06:47:58.000Z',
                bloodGlucose: 136,
                meal: 'Postprandial',
                usageRecordID: 29,
                uploadTime: '2021-04-25T13:22:04.628Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-08T06:41:19.000Z',
                bloodGlucose: 139,
                meal: 'Postprandial',
                usageRecordID: 30,
                uploadTime: '2021-04-25T13:22:04.791Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-11T06:43:41.000Z',
                bloodGlucose: 133,
                meal: 'Postprandial',
                usageRecordID: 31,
                uploadTime: '2021-04-25T13:22:04.964Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 32,
                created: '2021-04-25T13:22:05.133Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-14T06:04:56.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
                usageRecordID: 32,
                uploadTime: '2021-04-25T13:22:05.132Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 33,
                created: '2021-04-25T13:22:05.297Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-17T06:39:58.000Z',
                bloodGlucose: 139,
                meal: 'Postprandial',
                usageRecordID: 33,
                uploadTime: '2021-04-25T13:22:05.297Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-20T06:23:22.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
                usageRecordID: 34,
                uploadTime: '2021-04-25T13:22:05.499Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 35,
                created: '2021-04-25T13:22:05.679Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-23T06:10:33.000Z',
                bloodGlucose: 130,
                meal: 'Postprandial',
                usageRecordID: 35,
                uploadTime: '2021-04-25T13:22:05.678Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 36,
                created: '2021-04-25T13:22:05.896Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-26T06:32:12.000Z',
                bloodGlucose: 132,
                meal: 'Postprandial',
                usageRecordID: 36,
                uploadTime: '2021-04-25T13:22:05.894Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 37,
                created: '2021-04-25T13:22:06.074Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BG_HT100',
                deviceTime: '2021-04-29T06:11:04.000Z',
                bloodGlucose: 137,
                meal: 'Postprandial',
                usageRecordID: 37,
                uploadTime: '2021-04-25T13:22:06.073Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          ],
          bodyWeightFatMeasurements: [
            {
              measurement: {
                id: 28,
                created: '2021-04-25T13:22:04.437Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-02T06:49:27.000Z',
                weight: 69.7133,
                bmi: 24.7,
                bmr: 763.507,
                bodyFat: 16.9,
                bodyWater: 40.7232,
                muscleMass: 29.2796,
                visceralFat: 4,
                usageRecordID: 28,
                uploadTime: '2021-04-25T13:22:04.436Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 29,
                created: '2021-04-25T13:22:04.630Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-05T06:47:58.000Z',
                weight: 68.8666,
                bmi: 24.4,
                bmr: 762.347,
                bodyFat: 16.8,
                bodyWater: 40.4385,
                muscleMass: 28.2353,
                visceralFat: 4,
                usageRecordID: 29,
                uploadTime: '2021-04-25T13:22:04.628Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 30,
                created: '2021-04-25T13:22:04.795Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-08T06:41:19.000Z',
                weight: 69.431,
                bmi: 24.6,
                bmr: 763.121,
                bodyFat: 17.6,
                bodyWater: 40.6283,
                muscleMass: 27.7724,
                visceralFat: 5,
                usageRecordID: 30,
                uploadTime: '2021-04-25T13:22:04.791Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 31,
                created: '2021-04-25T13:22:04.964Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-11T06:43:41.000Z',
                weight: 67.7376,
                bmi: 24,
                bmr: 760.801,
                bodyFat: 17.3,
                bodyWater: 40.059,
                muscleMass: 28.4498,
                visceralFat: 4,
                usageRecordID: 31,
                uploadTime: '2021-04-25T13:22:04.964Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 32,
                created: '2021-04-25T13:22:05.133Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-14T06:04:56.000Z',
                weight: 69.1488,
                bmi: 24.5,
                bmr: 762.734,
                bodyFat: 18,
                bodyWater: 40.5334,
                muscleMass: 28.351,
                visceralFat: 5,
                usageRecordID: 32,
                uploadTime: '2021-04-25T13:22:05.132Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 33,
                created: '2021-04-25T13:22:05.298Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-17T06:39:58.000Z',
                weight: 70.2778,
                bmi: 24.9,
                bmr: 764.281,
                bodyFat: 16.9,
                bodyWater: 40.913,
                muscleMass: 28.8139,
                visceralFat: 4,
                usageRecordID: 33,
                uploadTime: '2021-04-25T13:22:05.297Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 34,
                created: '2021-04-25T13:22:05.499Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-20T06:23:22.000Z',
                weight: 68.5843,
                bmi: 24.3,
                bmr: 761.961,
                bodyFat: 18,
                bodyWater: 40.3436,
                muscleMass: 28.1196,
                visceralFat: 4,
                usageRecordID: 34,
                uploadTime: '2021-04-25T13:22:05.499Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 35,
                created: '2021-04-25T13:22:05.679Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-23T06:10:33.000Z',
                weight: 69.9955,
                bmi: 24.8,
                bmr: 763.894,
                bodyFat: 17.9,
                bodyWater: 40.8181,
                muscleMass: 28.6982,
                visceralFat: 4,
                usageRecordID: 35,
                uploadTime: '2021-04-25T13:22:05.678Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 36,
                created: '2021-04-25T13:22:05.906Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-26T06:32:12.000Z',
                weight: 69.1488,
                bmi: 24.5,
                bmr: 762.734,
                bodyFat: 16.2,
                bodyWater: 40.5334,
                muscleMass: 29.0425,
                visceralFat: 5,
                usageRecordID: 36,
                uploadTime: '2021-04-25T13:22:05.894Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 37,
                created: '2021-04-25T13:22:06.072Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: true,
                deviceID: 'RM_BF_LS212-B',
                deviceTime: '2021-04-29T06:11:04.000Z',
                weight: 67.7376,
                bmi: 24,
                bmr: 760.801,
                bodyFat: 16.7,
                bodyWater: 40.059,
                muscleMass: 28.4498,
                visceralFat: 4,
                usageRecordID: 37,
                uploadTime: '2021-04-25T13:22:06.073Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          ],
          bodyTempMeasurements: [
            {
              measurement: {
                id: 28,
                created: '2021-04-25T13:22:04.438Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-02T06:49:27.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-02T06:49:27.000Z',
                bodyTemperature: 37.4,
                usageRecordID: 28,
                uploadTime: '2021-04-25T13:22:04.436Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 29,
                created: '2021-04-25T13:22:04.629Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-05T06:47:58.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-05T06:47:58.000Z',
                bodyTemperature: 37.1,
                usageRecordID: 29,
                uploadTime: '2021-04-25T13:22:04.628Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 30,
                created: '2021-04-25T13:22:04.791Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-08T06:41:19.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-08T06:41:19.000Z',
                bodyTemperature: 37.4,
                usageRecordID: 30,
                uploadTime: '2021-04-25T13:22:04.791Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 31,
                created: '2021-04-25T13:22:04.954Z',
                updated: '2021-04-25T13:22:04.000Z',
                measured: '2021-04-11T06:43:41.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-11T06:43:41.000Z',
                bodyTemperature: 37.4,
                usageRecordID: 31,
                uploadTime: '2021-04-25T13:22:04.964Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 32,
                created: '2021-04-25T13:22:05.134Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-14T06:04:56.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-14T06:04:56.000Z',
                bodyTemperature: 37,
                usageRecordID: 32,
                uploadTime: '2021-04-25T13:22:05.132Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 33,
                created: '2021-04-25T13:22:05.299Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-17T06:39:58.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-17T06:39:58.000Z',
                bodyTemperature: 37.2,
                usageRecordID: 33,
                uploadTime: '2021-04-25T13:22:05.297Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 34,
                created: '2021-04-25T13:22:05.500Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-20T06:23:22.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-20T06:23:22.000Z',
                bodyTemperature: 37.2,
                usageRecordID: 34,
                uploadTime: '2021-04-25T13:22:05.499Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 35,
                created: '2021-04-25T13:22:05.680Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-23T06:10:33.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-23T06:10:33.000Z',
                bodyTemperature: 37.3,
                usageRecordID: 35,
                uploadTime: '2021-04-25T13:22:05.678Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 36,
                created: '2021-04-25T13:22:05.895Z',
                updated: '2021-04-25T13:22:05.000Z',
                measured: '2021-04-26T06:32:12.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-26T06:32:12.000Z',
                bodyTemperature: 37,
                usageRecordID: 36,
                uploadTime: '2021-04-25T13:22:05.894Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
            {
              measurement: {
                id: 37,
                created: '2021-04-25T13:22:06.073Z',
                updated: '2021-04-25T13:22:06.000Z',
                measured: '2021-04-29T06:11:04.000Z',
                isNormal: false,
                deviceID: 'RM_TM_HC700',
                deviceTime: '2021-04-29T06:11:04.000Z',
                bodyTemperature: 37.4,
                usageRecordID: 37,
                uploadTime: '2021-04-25T13:22:06.073Z',
              },
              member: {
                id: 2,
                created: '2021-04-25T13:22:04.280Z',
                updated: '2021-04-25T13:22:04.280Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          ],
        },
      }
    },
  },
  // 依啟用碼取得啟用狀態與購買人姓名
  {
    url: '/purchasedProgramInfoByCode',
    type: 'get',
    response(config) {
      const { code } = config.query
      let returnData = {}
      switch (code.slice(-1)) {
        case '0':
          returnData = mock({
            found: true,
            message: '成功',
            isUsed: false,
            barcode: '4710366311110',
            memberName: '@cname',
            programTitle: '健康方案-900',
            date: '@datetime()',
            purchaseDate: 1609925558000,
            sellId: 12345678,
          })
          break
        default:
          returnData = { status: 400, errCode: 'E0000', errMsg: {} }
          break
      }
      return returnData
    },
  },
  // 以常用搜尋取得量測資料
  {
    url: '/measurements/rules/post',
    type: 'post',
    response() {
      return {
        result: [
          {
            id: 7,
            created: '2021-04-27T11:53:31.790Z',
            updated: '2021-04-27T11:53:31.000Z',
            measured: '2020-04-13T06:59:40.000Z',
            isNormal: true,
            deviceID: 'RM_BP_X3',
            deviceTime: '2020-04-13T06:59:40.000Z',
            diastolic: 87,
            systolic: 181,
            pulse: 73,
            afib: false,
            arr: true,
            pc: true,
            ihb: false,
            pulsePressureDifference: 49,
            scene: null,
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          },
          {
            id: 5,
            created: '2021-04-23T04:19:47.819Z',
            updated: '2021-04-23T04:19:47.000Z',
            measured: '2020-04-13T06:59:40.000Z',
            isNormal: true,
            deviceID: 'RM_BP_X3',
            deviceTime: '2020-04-13T06:59:40.000Z',
            diastolic: 87,
            systolic: 180,
            pulse: 73,
            afib: false,
            arr: true,
            pc: true,
            ihb: false,
            pulsePressureDifference: 49,
            scene: null,
            subject: {
              id: 20,
              created: '2021-04-14T04:11:33.332Z',
              updated: '2021-04-26T07:04:14.000Z',
              cardNumber: '0100000420',
              cardHiddenCode: '500',
              memberID: '1511001',
              hasOwnedCard: false,
              cache: {
                id: 105,
                created: '2021-04-23T04:03:04.010Z',
                updated: '2021-04-23T04:03:04.010Z',
                cardNumber: '0100000420',
                cardHiddenCode: '500',
                memberID: '1511001',
                name: '案例一',
                level: '一般會員',
              },
            },
          },
        ],
      }
    },
  },
]
