<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, watch, toRef } from 'vue'
  import { getAllDates, parseTime, dateDiff } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      // let pulse,
      //   systolic,
      //   diastolic,
      //   bpNormal,
      //   bpUnNormal = []
      const option = ref({})
      const echartInit = () => {
        // 排序資料
        dataCopy.value.sort((a, b) => new Date(a.created) - new Date(b.created))

        // 日期範圍
        const dateMin = parseTime(dataCopy.value[0].created, '{y}-{m}-{d}')
        const dateMax = parseTime(
          dataCopy.value[dataCopy.value.length - 1].created,
          '{y}-{m}-{d}'
        )
        const dateRange = getAllDates(dateMin, dateMax)

        const total_count = dataCopy.value.reduce((obj, item) => {
          // 日期
          const date = item.created.split('T')[0]
          if (!obj[date]) {
            obj[date] = { people: {} }
          }

          // 統計人
          if (item.hubCode in obj[date].people) {
            obj[date].people[item.hubCode]++
          } else {
            obj[date].people[item.hubCode] = 1
          }

          return obj
        }, {})
        console.log(total_count)
        const people = new Array(dateRange.length).fill(0) // 人次
        const peopleTimes = new Array(dateRange.length).fill(0) // 人數

        // 轉成陣列(繪圖用)
        Object.keys(total_count).forEach((day) => {
          console.log(day)
          // 人次
          people[dateDiff(day, dateMin)] = Object.keys(
            total_count[day].people
          ).length

          // 人數
          peopleTimes[dateDiff(day, dateMin)] = Object.values(
            total_count[day].people
          ).reduce((a, b) => a + b)
        })
        console.log(people)
        console.log(peopleTimes)

        const data = [
          {
            name: '個案人數',
            type: 'bar',
            barWidth: 10,
            stack: '血壓',
            data: people,
          },
          // {
          //   name: '正常記錄',
          //   type: 'bar',
          //   barWidth: 10,
          //   stack: '血壓',
          //   data: bpNormal,
          // },
          // {
          //   name: '收縮壓',
          //   type: 'line',
          //   data: systolic,
          // },
          // {
          //   name: '舒張壓',
          //   type: 'line',
          //   data: diastolic,
          // },
          // {
          //   name: '脈搏',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   data: pulse,
          // },
        ]
        option.value = {
          title: {
            text: '血壓紀錄',
            textStyle: {
              fontSize: '20',
            },
            left: '30',
          },
          color: [
            'rgba(189, 189, 189, 1)',
            '#EB5757',
            '#F2994A',
            '#F2C94C',
            '#219653',
            '#2F80ED',
          ],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            // formatter: (params) => {
            //   let tar = `量測日期 ${moment(params[0].value[0]).format(
            //     'YYYY-MM-DD'
            //   )}<br/>`
            //   tar += `<span class="chart-tooltip-point" style="background-color:${params[3].color};"></span>
            //   ${params[3].seriesName}:  ${params[3].value[1]} 毫米汞柱<br/>`
            //   tar += `<span class="chart-tooltip-point" style="background-color:${params[4].color};"></span>
            //   ${params[4].seriesName}:  ${params[4].value[1]} 毫米汞柱<br/>`
            //   if (params[1].value[1] != '-') {
            //     tar += `<span class="chart-tooltip-point" style="background-color:${params[1].color};"></span>
            //     脈壓差:  ${params[1].value[1]} 毫米汞柱<br/>`
            //   } else {
            //     tar += `<span class="chart-tooltip-point" style="background-color:${params[2].color};"></span>
            //     脈壓差:  ${params[2].value[1]} 毫米汞柱<br/>`
            //   }
            //   tar += `<span class="chart-tooltip-point" style="background-color:${params[5].color};"></span>
            //   ${params[5].seriesName}:  ${params[5].value[1]} 次/分鐘<br/>`
            //   return tar
            // },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['個案人數'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'category',
              data: dateRange,
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
            // {
            //   type: 'value',
            //   name: '毫米汞柱',
            //   min: bpChartRange.min,
            //   max: bpChartRange.max,
            //   interval: bpChartRange.interval,
            //   axisLabel: {
            //     formatter: '{value}',
            //   },
            // },
            // {
            //   type: 'value',
            //   name: '次/分鐘',
            //   min: pulseChartRange.min,
            //   max: pulseChartRange.max,
            //   interval: pulseChartRange.interval,
            //   axisLabel: {
            //     formatter: '{value}',
            //   },
            // },
          ],
          series: data,
        }
      }
      // onMounted(() => {
      //   echartInit()
      // })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        props,
        echartInit,
        option,
      }
    },
  }
</script>

<style></style>
