<template>
  <div class="search-box">
    <a-row>
      <a-col :sm="24" :md="4">查詢條件</a-col>
      <a-col :sm="24" :md="20">
        <a-form-item label="常用搜尋名稱" style="margin-top: 20px">
          <a-input v-model:value="searchData.searchString" />
        </a-form-item>
        <a-button type="primary" @click="handleSearch">查詢</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    setup(props, { emit }) {
      const store = useStore()
      const searchData = computed(() => store.getters['searchSetting/search'])

      const handleSearch = () => {
        emit('search')
      }
      return {
        searchData,
        handleSearch,
      }
    },
  }
</script>

<style lang="less">
  .search-box {
    background-color: #fff;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    padding: 20px;

    .ant-form-item {
      label {
        margin-right: 20px;
      }
      input {
        width: 300px;
      }
    }

    button {
      margin: 10px;
    }
  }
</style>
