<template>
  <div class="searchadvancedSetting-container">
    <a-page-header title="健康記錄進階常用搜尋定義">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <SearchBlack :filter="filter" @search="handleSearch"></SearchBlack>
    <a-button type="primary" @click="handleAddBasciSetting">
      新增常用搜尋
    </a-button>
    <DataTable
      typeString="advancedSetting"
      @edit="handleEdit"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'

  import SearchBlack from '@/views/setting/SearchBlack'
  import DataTable from '@/views/setting/DataTable'
  export default {
    name: 'accountManagement',
    components: {
      QuestionCircleOutlined,
      SearchBlack,
      DataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const filter = computed(() => store.getters['cust/filter'])

      const handleSearch = () => {
        store.dispatch('searchSetting/getSettingList', { type: 'advanced' })
      }

      const handleAddBasciSetting = () => {
        router.push({
          path: `searchSetting/advanced/-1`,
        })
      }
      const handleEdit = (id) => {
        router.push({
          path: `searchSetting/advanced/${id}`,
        })
      }

      const handleDelete = (id) => {
        store.dispatch('searchSetting/removeSettingList', {
          id,
          type: 'advanced',
        })
      }

      return {
        filter,
        handleSearch,
        handleAddBasciSetting,
        handleEdit,
        handleDelete,
      }
    },
  }
</script>
<style lang="less">
  .searchadvancedSetting-container {
  }
</style>
