module.exports = [
  // 取得門市分區資訊
  {
    url: '/stores',
    type: 'get',
    response() {
      return {
        result: {
          found: true,
          area: [
            {
              area_id: 1,
              area_name: '桃竹區',
              area_sub: [
                {
                  area_sub_id: 1,
                  area_sub_name: '桃園區',
                  store: [
                    {
                      store_id: 71,
                      store_name: '1401佑全中壢中山藥局',
                      PostalCode: '320',
                      County: '桃園市',
                      District: '中壢區',
                      Address: '桃園市中壢區中山東路二段27號1樓',
                      Section: '北部',
                      Tel: '03-4385373',
                      Lat: 24.95439,
                      Lng: 121.23735,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 160,
                      store_name: 'A1401蘆竹奉化店',
                      PostalCode: '338',
                      County: '桃園市',
                      District: '蘆竹區',
                      Address: '桃園縣蘆竹鄉奉化路142號1F',
                      Section: '北部',
                      Tel: '03-3528361',
                      Lat: 25.039291,
                      Lng: 121.288964,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 217,
                      store_name: '1411桃園大有店',
                      PostalCode: '330',
                      County: '桃園市',
                      District: '桃園區',
                      Address: '桃園市桃園區大有路556路',
                      Section: '北部',
                      Tel: '03-3460793',
                      Lat: 25.0134126,
                      Lng: 121.3181659,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 223,
                      store_name: '1412中壢民權藥局',
                      PostalCode: '320',
                      County: '桃園市',
                      District: '中壢區',
                      Address: '桃園市中壢區民權路326.328號',
                      Section: '北部',
                      Tel: '03-4014961',
                      Lat: 24.9628545,
                      Lng: 121.2195639,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 230,
                      store_name: '1413中壢中正店',
                      PostalCode: '320',
                      County: '桃園市',
                      District: '中壢區',
                      Address: '桃園市中壢區石頭里中正路108號',
                      Section: '北部',
                      Tel: '03-2807899',
                      Lat: 24.954989,
                      Lng: 121.222915,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 2,
                  area_sub_name: '新竹一區',
                  store: [
                    {
                      store_id: 2,
                      store_name: '1501新竹食品店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市食品路170號1F',
                      Section: '北部',
                      Tel: '03-5627307',
                      Lat: 24.797388,
                      Lng: 120.976346,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 5,
                      store_name: '1504佑全竹北中正藥局',
                      PostalCode: '302',
                      County: '新竹縣',
                      District: '竹北市',
                      Address: '新竹縣竹北市中正西路176、178號1F',
                      Section: '北部',
                      Tel: '03-5556235',
                      Lat: 24.84142,
                      Lng: 120.9996,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 7,
                      store_name: '1506新竹林森店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市林森路278號1F',
                      Section: '北部',
                      Tel: '03-5228288',
                      Lat: 24.79759,
                      Lng: 120.96194,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 118,
                      store_name: '1515竹北自強南店',
                      PostalCode: '302',
                      County: '新竹縣',
                      District: '竹北市',
                      Address: '新竹縣竹北市自強南路160號',
                      Section: '北部',
                      Tel: '03-6681950',
                      Lat: 24.815865,
                      Lng: 121.024485,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 3,
                  area_sub_name: '新竹二區',
                  store: [
                    {
                      store_id: 3,
                      store_name: '1502佑全新竹光華藥局',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市光華街86-1號1F',
                      Section: '北部',
                      Tel: '03-5311313',
                      Lat: 24.817352,
                      Lng: 120.972345,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 20,
                      store_name: '1509佑全新竹光復藥局',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市光復路一段353號1F',
                      Section: '北部',
                      Tel: '03-6661826',
                      Lat: 24.78319,
                      Lng: 121.01765,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 119,
                      store_name: '1514佑全新竹自由藥局',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市東區復興里自由路107號1樓',
                      Section: '北部',
                      Tel: '03-5428497',
                      Lat: 24.814,
                      Lng: 120.98,
                      HasParking: true,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 166,
                      store_name: 'A5103新竹湳雅店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市湳雅街95號',
                      Section: '北部',
                      Tel: '03-5356731',
                      Lat: 24.818132,
                      Lng: 120.97087,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 175,
                      store_name: '1516新竹建巧',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市建斥?46.148號',
                      Section: '北部',
                      Tel: '03-5169340',
                      Lat: 24.800799,
                      Lng: 120.997171,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 17,
                  area_sub_name: '新竹竹東區',
                  store: [
                    {
                      store_id: 61,
                      store_name: '1510竹東長春店',
                      PostalCode: '310',
                      County: '新竹縣',
                      District: '竹東鎮',
                      Address: '新竹縣竹東鎮長春路三段138.140號144巷2號1F',
                      Section: '北部',
                      Tel: '03-5961337',
                      Lat: 24.74309,
                      Lng: 121.0841,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 89,
                      store_name: '1512竹東東峰店',
                      PostalCode: '310',
                      County: '新竹縣',
                      District: '竹東鎮',
                      Address: '新竹縣竹東鎮東峰路33號1F',
                      Section: '北部',
                      Tel: '03-5959146',
                      Lat: 24.7213,
                      Lng: 121.0965,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 174,
                      store_name: 'A1501竹東中興店',
                      PostalCode: '310',
                      County: '新竹縣',
                      District: '竹東鎮',
                      Address: '新竹縣竹東鎮中興路4段195號52館B116室',
                      Section: '北部',
                      Tel: '03-5910472',
                      Lat: 24.77457,
                      Lng: 121.04573,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '週一~週五08:30~18:00、週六、週日公休',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 239,
                      store_name: '1701佑全花蓮中山藥局',
                      PostalCode: '970',
                      County: '花蓮縣',
                      District: '花蓮市',
                      Address: '花蓮縣花蓮市國安里中山路326號1、 2樓',
                      Section: '東部',
                      Tel: '03-8360199',
                      Lat: 23.9818119,
                      Lng: 121.6035094,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~21:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 37,
                  area_sub_name: '新竹三區',
                  store: [
                    {
                      store_id: 6,
                      store_name: '1505竹北三民店',
                      PostalCode: '302',
                      County: '新竹縣',
                      District: '竹北市',
                      Address: '新竹縣竹北市三民路3號1F',
                      Section: '北部',
                      Tel: '03-5520508',
                      Lat: 24.834994,
                      Lng: 121.018638,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 63,
                      store_name: '1511新竹中正店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '北區',
                      Address: '新竹市北區中正路335號1F',
                      Section: '北部',
                      Tel: '03-5437037',
                      Lat: 24.81448,
                      Lng: 120.96248,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 98,
                      store_name: '1513新竹南大店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市南大路358號1、2F',
                      Section: '北部',
                      Tel: '03-5628225',
                      Lat: 24.798101,
                      Lng: 120.970371,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 165,
                      store_name: 'A5102新竹忠孝店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '新竹市',
                      Address: '新竹市忠孝路300號',
                      Section: '北部',
                      Tel: '03-5610615',
                      Lat: 24.804978,
                      Lng: 120.98423,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 268,
                      store_name: '1517新竹經國店',
                      PostalCode: '300',
                      County: '新竹市',
                      District: '北區',
                      Address: '新竹市北區經國路二段368、374號',
                      Section: '北部',
                      Tel: '03-5264086',
                      Lat: 24.8076919,
                      Lng: 120.9553494,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 2,
              area_name: '中A區',
              area_sub: [
                {
                  area_sub_id: 4,
                  area_sub_name: '北苗區',
                  store: [
                    {
                      store_id: 17,
                      store_name: '2102頭份信義店',
                      PostalCode: '351',
                      County: '苗栗縣',
                      District: '頭份市',
                      Address: '苗栗縣頭份鎮信義路82號1F',
                      Section: '北部',
                      Tel: '037-592608',
                      Lat: 24.685705,
                      Lng: 120.908141,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 106,
                      store_name: '2105竹南延平店',
                      PostalCode: '350',
                      County: '苗栗縣',
                      District: '竹南鎮',
                      Address: '苗栗縣竹南鎮延平路89、91號1F',
                      Section: '北部',
                      Tel: '037-475915',
                      Lat: 24.688446,
                      Lng: 120.876403,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 107,
                      store_name: '2106頭份建國店',
                      PostalCode: '351',
                      County: '苗栗縣',
                      District: '頭份市',
                      Address: '苗栗縣頭份鎮建國路64號',
                      Section: '北部',
                      Tel: '037-695641',
                      Lat: 24.69418,
                      Lng: 120.91263,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 238,
                      store_name: '2112竹南維新店',
                      PostalCode: '350',
                      County: '苗栗縣',
                      District: '竹南鎮',
                      Address: '苗栗縣竹南鎮維新路142號',
                      Section: '北部',
                      Tel: '037-634409',
                      Lat: 24.6864907,
                      Lng: 120.8795121,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 5,
                  area_sub_name: '海線一區',
                  store: [
                    {
                      store_id: 88,
                      store_name: '2310大甲蔣公店',
                      PostalCode: '437',
                      County: '台中市',
                      District: '大甲區',
                      Address: '台中市大甲區蔣公路260號1F',
                      Section: '中部',
                      Tel: '04-26865216',
                      Lat: 24.347379,
                      Lng: 120.61912,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 116,
                      store_name: '2108通霄中山店',
                      PostalCode: '357',
                      County: '苗栗縣',
                      District: '通霄鎮',
                      Address: '苗栗縣通霄鎮中山路75號1F',
                      Section: '北部',
                      Tel: '037-750958',
                      Lat: 24.48789,
                      Lng: 120.67823,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 117,
                      store_name: '2317大甲文武店',
                      PostalCode: '437',
                      County: '台中市',
                      District: '大甲區',
                      Address: '台中市大甲區文武路26-2號1F',
                      Section: '中部',
                      Tel: '04-26800569',
                      Lat: 24.345912,
                      Lng: 120.625722,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 180,
                      store_name: '2110苑裡和平店',
                      PostalCode: '358',
                      County: '苗栗縣',
                      District: '苑裡鎮',
                      Address: '苗栗縣苑裡鎮和平路18號1F',
                      Section: '北部',
                      Tel: '037-866290',
                      Lat: 24.440358,
                      Lng: 120.656335,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 6,
                  area_sub_name: '豐原一區',
                  store: [
                    {
                      store_id: 45,
                      store_name: '2309豐原西勢藥局',
                      PostalCode: '420',
                      County: '台中市',
                      District: '豐原區',
                      Address: '台中市豐原區西勢路200號1F',
                      Section: '中部',
                      Tel: '04-25262330',
                      Lat: 24.24377,
                      Lng: 120.70679,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 121,
                      store_name: '2315豐原三豐店',
                      PostalCode: '420',
                      County: '台中市',
                      District: '豐原區',
                      Address: '台中市豐原區西湳里三豐路1段411號',
                      Section: '中部',
                      Tel: '04-25292190',
                      Lat: 24.26384,
                      Lng: 120.72007,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 127,
                      store_name: '2316后里甲后店',
                      PostalCode: '421',
                      County: '台中市',
                      District: '后里區',
                      Address: '台中市后里區甲后路一段728號',
                      Section: '中部',
                      Tel: '04-25583116',
                      Lat: 24.309439,
                      Lng: 120.719284,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 222,
                      store_name: '2323豐原中正店',
                      PostalCode: '420',
                      County: '台中市',
                      District: '豐原區',
                      Address: '臺中市豐原區中正路223.225號',
                      Section: '中部',
                      Tel: '04-25152886',
                      Lat: 24.2518943,
                      Lng: 120.7174969,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 16,
                  area_sub_name: '南苗區',
                  store: [
                    {
                      store_id: 22,
                      store_name: '2103苗栗中正店',
                      PostalCode: '360',
                      County: '苗栗縣',
                      District: '苗栗市',
                      Address: '苗栗市中苗里中正路437號1F',
                      Section: '北部',
                      Tel: '037-376508',
                      Lat: 24.560078,
                      Lng: 120.82209,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 70,
                      store_name: '2104苗栗為公店',
                      PostalCode: '360',
                      County: '苗栗縣',
                      District: '苗栗市',
                      Address: '苗栗市為公路132.132-5號1F',
                      Section: '北部',
                      Tel: '037-279089',
                      Lat: 24.57046,
                      Lng: 120.82622,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 110,
                      store_name: '2107後龍中華店',
                      PostalCode: '356',
                      County: '苗栗縣',
                      District: '後龍鎮',
                      Address: '苗栗縣後龍鎮中華路51-3號1F',
                      Section: '北部',
                      Tel: '037-726722',
                      Lat: 24.616551,
                      Lng: 120.792158,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 126,
                      store_name: '2109公館大同店',
                      PostalCode: '363',
                      County: '苗栗縣',
                      District: '公館鄉',
                      Address: '苗栗縣公館鄉玉泉村13鄰玉泉359-3號',
                      Section: '北部',
                      Tel: '037-231147',
                      Lat: 24.50645,
                      Lng: 120.82685,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 218,
                      store_name: '2111銅鑼中正店',
                      PostalCode: '366',
                      County: '苗栗縣',
                      District: '銅鑼鄉',
                      Address: '苗栗縣銅鑼鄉中正路201號1F',
                      Section: '北部',
                      Tel: '037-981083',
                      Lat: 24.4894842,
                      Lng: 120.7878124,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 30,
                  area_sub_name: '豐原二區',
                  store: [
                    {
                      store_id: 19,
                      store_name: '2308神岡中山店',
                      PostalCode: '429',
                      County: '台中市',
                      District: '神岡區',
                      Address: '台中市神岡區中山路549.549-1.549-2 號1F',
                      Section: '中部',
                      Tel: '04-25634677',
                      Lat: 24.24633,
                      Lng: 120.68424,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 122,
                      store_name: '2318大雅雅環店',
                      PostalCode: '428',
                      County: '台中市',
                      District: '大雅區',
                      Address: '台中市大雅區雅環路二段88號',
                      Section: '中部',
                      Tel: '04-25605942',
                      Lat: 24.226253,
                      Lng: 120.652956,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 232,
                      store_name: '2324佑全豐原圓環東藥局',
                      PostalCode: '420',
                      County: '台中市',
                      District: '豐原區',
                      Address: '臺中市豐原區北陽里圓環東路548號.554號',
                      Section: '中部',
                      Tel: '04-25246069',
                      Lat: 24.248241,
                      Lng: 120.727614,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 31,
                  area_sub_name: '海線二區',
                  store: [
                    {
                      store_id: 9,
                      store_name: '2301沙鹿中山店',
                      PostalCode: '433',
                      County: '台中市',
                      District: '沙鹿區',
                      Address: '台中市沙鹿區中山路267號1F',
                      Section: '中部',
                      Tel: '04-26626363',
                      Lat: 24.23772,
                      Lng: 120.56061,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 11,
                      store_name: '2303清水中山店',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '台中市清水區中山路166號1F',
                      Section: '中部',
                      Tel: '04-26235961',
                      Lat: 24.269997,
                      Lng: 120.57593,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 114,
                      store_name: '2314清水中山二店',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '台中市清水區中山路29-2號',
                      Section: '中部',
                      Tel: '04-26224469',
                      Lat: 24.260588,
                      Lng: 120.571477,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 176,
                      store_name: '2319沙鹿光大店',
                      PostalCode: '433',
                      County: '台中市',
                      District: '沙鹿區',
                      Address: '台中市沙鹿區光大路100號',
                      Section: '中部',
                      Tel: '04-26652375',
                      Lat: 24.24464,
                      Lng: 120.56373,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 258,
                      store_name: '2326清水中華店',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '臺中市清水區中華路460號1樓',
                      Section: '中部',
                      Tel: '04-26224451',
                      Lat: 24.2746764,
                      Lng: 120.5685516,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 265,
                      store_name: '2327清水中山三店',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '台中市清水區清水里中山路24、26、28號',
                      Section: '中部',
                      Tel: '04-26224469',
                      Lat: 24.2604276,
                      Lng: 120.5698941,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 3,
              area_name: '南區',
              area_sub: [
                {
                  area_sub_id: 10,
                  area_sub_name: '中彰北區',
                  store: [
                    {
                      store_id: 46,
                      store_name: '2401員林中正店',
                      PostalCode: '510',
                      County: '彰化縣',
                      District: '員林市',
                      Address: '彰化縣員林鎮中正路135.137號',
                      Section: '中部',
                      Tel: '048-368669',
                      Lat: 23.9524,
                      Lng: 120.5755,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 96,
                      store_name: '2405鹿港民族店',
                      PostalCode: '505',
                      County: '彰化縣',
                      District: '鹿港鎮',
                      Address: '彰化縣鹿港鎮民族路67.69號1F',
                      Section: '中部',
                      Tel: '047-751139',
                      Lat: 24.05584,
                      Lng: 120.43648,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 97,
                      store_name: '2406彰化民族店',
                      PostalCode: '500',
                      County: '彰化縣',
                      District: '彰化市',
                      Address: '彰化市民族路450號1F',
                      Section: '中部',
                      Tel: '04-7286549',
                      Lat: 24.07618,
                      Lng: 120.54026,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 101,
                      store_name: '2407和美鹿和店',
                      PostalCode: '508',
                      County: '彰化縣',
                      District: '和美鎮',
                      Address: '彰化縣和美鎮鹿和路6段441號1F',
                      Section: '中部',
                      Tel: '047-559401',
                      Lat: 24.11031,
                      Lng: 120.49612,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 11,
                  area_sub_name: '中投區',
                  store: [
                    {
                      store_id: 66,
                      store_name: '2501竹山集山店',
                      PostalCode: '557',
                      County: '南投縣',
                      District: '竹山鎮',
                      Address: '南投縣竹山鎮集山路3段955號1樓',
                      Section: '中部',
                      Tel: '049-2656614',
                      Lat: 23.7561,
                      Lng: 120.68191,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 68,
                      store_name: '2503南投民族店',
                      PostalCode: '540',
                      County: '南投縣',
                      District: '南投市',
                      Address: '南投市民族路235號1F',
                      Section: '中部',
                      Tel: '049-2204502',
                      Lat: 23.90888,
                      Lng: 120.68758,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 181,
                      store_name: '2506草屯中興店',
                      PostalCode: '542',
                      County: '南投縣',
                      District: '草屯鎮',
                      Address: '南投縣草屯鎮中興路78號',
                      Section: '中部',
                      Tel: '049-2323440',
                      Lat: 23.974302,
                      Lng: 120.682039,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 247,
                      store_name: '2602佑全斗六慶生藥局',
                      PostalCode: '640',
                      County: '雲林縣',
                      District: '斗六市',
                      Address: '雲林縣斗六市公誠里慶生路26號',
                      Section: '中部',
                      Tel: '05-5372736',
                      Lat: 23.7128707,
                      Lng: 120.5380354,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 12,
                  area_sub_name: '嘉南區',
                  store: [
                    {
                      store_id: 94,
                      store_name: '2704嘉義興業西店',
                      PostalCode: '600',
                      County: '嘉義市',
                      District: '嘉義市',
                      Address: '嘉義市興業西路317號1F',
                      Section: '南部',
                      Tel: '05-2842684',
                      Lat: 23.467682,
                      Lng: 120.43838,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 95,
                      store_name: '2705嘉義吳鳳南店',
                      PostalCode: '600',
                      County: '嘉義市',
                      District: '嘉義市',
                      Address: '嘉義市吳鳳南路585.587號1F',
                      Section: '南部',
                      Tel: '05-2396800',
                      Lat: 23.456377,
                      Lng: 120.45898,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 123,
                      store_name: '3101新營復興店',
                      PostalCode: '730',
                      County: '台南市',
                      District: '新營區',
                      Address: '台南市新營區復興路150-7號',
                      Section: '南部',
                      Tel: '06-6327382',
                      Lat: 23.303182,
                      Lng: 120.30805,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 182,
                      store_name: '3103佳里中山店',
                      PostalCode: '722',
                      County: '台南市',
                      District: '佳里區',
                      Address: '台南市佳里區中山路112號',
                      Section: '南部',
                      Tel: '06-7231450',
                      Lat: 23.156571,
                      Lng: 120.177534,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 198,
                      store_name: '3305三民明誠藥局',
                      PostalCode: '813',
                      County: '高雄市',
                      District: '左營區',
                      Address: '高雄市三民區明誠一路500號1、2F及502號',
                      Section: '南部',
                      Tel: '07-3493137',
                      Lat: 22.661048,
                      Lng: 120.304972,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 216,
                      store_name: '3306佑全鳳山藥局',
                      PostalCode: '830',
                      County: '高雄市',
                      District: '鳳山區',
                      Address: '高雄市鳳山區青年路二段463號',
                      Section: '南部',
                      Tel: '07-7801568',
                      Lat: 22.6452483,
                      Lng: 120.3499431,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 221,
                      store_name: '3104佑全中華藥局',
                      PostalCode: '701',
                      County: '台南市',
                      District: '東區',
                      Address: '台南市東區中華東路三段215號',
                      Section: '南部',
                      Tel: '06-2906386',
                      Lat: 22.9763823,
                      Lng: 120.2252582,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 35,
                  area_sub_name: '中彰南區',
                  store: [
                    {
                      store_id: 53,
                      store_name: '2402田中中正店',
                      PostalCode: '520',
                      County: '彰化縣',
                      District: '田中鎮',
                      Address: '彰化縣田中鎮中正路305號',
                      Section: '中部',
                      Tel: '048-761239',
                      Lat: 23.85852,
                      Lng: 120.58285,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 102,
                      store_name: '2408北斗中華店',
                      PostalCode: '521',
                      County: '彰化縣',
                      District: '北斗鎮',
                      Address: '彰化縣北斗鎮中華路210號1F',
                      Section: '中部',
                      Tel: '04-8873383',
                      Lat: 23.87337,
                      Lng: 120.52348,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 109,
                      store_name: '2409二林中山店',
                      PostalCode: '526',
                      County: '彰化縣',
                      District: '二林鎮',
                      Address: '彰化縣二林鎮中山路137號1F',
                      Section: '中部',
                      Tel: '04-8965338',
                      Lat: 23.899789,
                      Lng: 120.367307,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 237,
                      store_name: '2601佑全西螺建興藥局',
                      PostalCode: '648',
                      County: '雲林縣',
                      District: '西螺鎮',
                      Address: '雲林縣西螺鎮光華里建興路249號',
                      Section: '中部',
                      Tel: '05-5883161',
                      Lat: 23.8007904,
                      Lng: 120.4570395,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 5,
              area_name: '中B區',
              area_sub: [
                {
                  area_sub_id: 7,
                  area_sub_name: '中縣區',
                  store: [
                    {
                      store_id: 28,
                      store_name: '5301大里國光店(大買家)',
                      PostalCode: '412',
                      County: '台中市',
                      District: '大里區',
                      Address: '臺中市大里區國光路2段710號1樓',
                      Section: '中部',
                      Tel: '04-24818633',
                      Lat: 24.11521,
                      Lng: 120.67951,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 103,
                      store_name: '2312大里中興店',
                      PostalCode: '412',
                      County: '台中市',
                      District: '大里區',
                      Address: '台中市大里區中興路2段129號1F',
                      Section: '中部',
                      Tel: '04-24836595',
                      Lat: 24.10435,
                      Lng: 120.6889,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 178,
                      store_name: '2220太平中興店',
                      PostalCode: '411',
                      County: '台中市',
                      District: '太平區',
                      Address: '台中市太平區中興路86號',
                      Section: '中部',
                      Tel: '04-23512301',
                      Lat: 24.127681,
                      Lng: 120.716059,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 184,
                      store_name: '2221佑全太平藥局',
                      PostalCode: '411',
                      County: '台中市',
                      District: '太平區',
                      Address: '台中市太平區樹德路121號',
                      Section: '中部',
                      Tel: '04-23910016',
                      Lat: 24.154399,
                      Lng: 120.711838,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 8,
                  area_sub_name: 'B1區',
                  store: [
                    {
                      store_id: 51,
                      store_name: '2204台中永福店',
                      PostalCode: '407',
                      County: '台中市',
                      District: '西屯區',
                      Address: '台中市西屯區永福路168-1號1F',
                      Section: '中部',
                      Tel: '04-24637635',
                      Lat: 24.185315,
                      Lng: 120.621501,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 56,
                      store_name: '2208台中大鵬店',
                      PostalCode: '406',
                      County: '台中市',
                      District: '北屯區',
                      Address: '台中市北屯區大鵬路14號1F',
                      Section: '中部',
                      Tel: '04-22955838',
                      Lat: 24.178245,
                      Lng: 120.667723,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 99,
                      store_name: '2213台中興安店',
                      PostalCode: '406',
                      County: '台中市',
                      District: '北屯區',
                      Address: '台中市北屯區興安路一段253號1F',
                      Section: '中部',
                      Tel: '04-22490355',
                      Lat: 24.172214,
                      Lng: 120.694176,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 173,
                      store_name: '2218台中四平店',
                      PostalCode: '406',
                      County: '台中市',
                      District: '北屯區',
                      Address: '台中市北屯區四平路360號',
                      Section: '中部',
                      Tel: '04-24212792',
                      Lat: 24.193346,
                      Lng: 120.681869,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 248,
                      store_name: '2228佑全台中中華藥局',
                      PostalCode: '404',
                      County: '台中市',
                      District: '北區',
                      Address: '臺中市中區中華里中華路一段162號1樓',
                      Section: '中部',
                      Tel: '04-22291556',
                      Lat: 24.1461504,
                      Lng: 120.675623,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '24hr',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 9,
                  area_sub_name: 'B2區',
                  store: [
                    {
                      store_id: 13,
                      store_name: '2305龍井東海店',
                      PostalCode: '434',
                      County: '台中市',
                      District: '龍井區',
                      Address: '台中市龍井區新興路73-2號1F',
                      Section: '中部',
                      Tel: '04-26316110',
                      Lat: 24.18173,
                      Lng: 120.58531,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 52,
                      store_name: '2203台中向上店',
                      PostalCode: '403',
                      County: '台中市',
                      District: '西區',
                      Address: '台中市西區向上路1段386號1F',
                      Section: '中部',
                      Tel: '04-23204732',
                      Lat: 24.14681,
                      Lng: 120.65585,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 124,
                      store_name: '2217大肚沙田店',
                      PostalCode: '432',
                      County: '台中市',
                      District: '大肚區',
                      Address: '台中市大肚區沙田路三段51.53號1.2F',
                      Section: '中部',
                      Tel: '04-26992084',
                      Lat: 24.1556,
                      Lng: 120.544,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 177,
                      store_name: '2219台中忠勇店',
                      PostalCode: '408',
                      County: '台中市',
                      District: '南屯區',
                      Address: '台中市南屯區忠勇路51-8號及51-8號 2F',
                      Section: '中部',
                      Tel: '04-23803282',
                      Lat: 24.143692,
                      Lng: 120.614183,
                      HasParking: true,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 255,
                      store_name: '2229佑全台中烏日藥局',
                      PostalCode: '414',
                      County: '台中市',
                      District: '烏日區',
                      Address: '臺中市烏日區中山路1段354號1樓',
                      Section: '中部',
                      Tel: '04-23367756',
                      Lat: 24.1081504,
                      Lng: 120.6364991,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 28,
                  area_sub_name: 'B3區',
                  store: [
                    {
                      store_id: 161,
                      store_name: 'A2201台中美村店',
                      PostalCode: '402',
                      County: '台中市',
                      District: '南區',
                      Address: '台中市南區美村南路131號',
                      Section: '中部',
                      Tel: '04-22633936',
                      Lat: 24.117184,
                      Lng: 120.666772,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 195,
                      store_name: '2223台中漢口二店',
                      PostalCode: '404',
                      County: '台中市',
                      District: '北區',
                      Address: '台中市北區漢口路3段183.185號1F',
                      Section: '中部',
                      Tel: '04-22936166',
                      Lat: 24.166807,
                      Lng: 120.67004,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 213,
                      store_name: '2225台中黎明二店',
                      PostalCode: '408',
                      County: '台中市',
                      District: '南屯區',
                      Address: '台中市南屯區黎明路二段268號',
                      Section: '中部',
                      Tel: '04-23805560',
                      Lat: 24.148341,
                      Lng: 120.635823,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 226,
                      store_name: '2227佑全福星藥局',
                      PostalCode: '407',
                      County: '台中市',
                      District: '西屯區',
                      Address: '台中市西屯區黎明路三段395、397號',
                      Section: '中部',
                      Tel: '04-24527465',
                      Lat: 24.1828459,
                      Lng: 120.6392004,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 229,
                      store_name: '2226佑全台中五權藥局',
                      PostalCode: '404',
                      County: '台中市',
                      District: '北區',
                      Address: '臺中市北區錦平里五權路496號',
                      Section: '中部',
                      Tel: '04-22080702',
                      Lat: 24.153413,
                      Lng: 120.683212,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 7,
              area_name: '總部',
              area_sub: [
                {
                  area_sub_id: 13,
                  area_sub_name: '總部',
                  store: [
                    {
                      store_id: 1,
                      store_name: '888台中總部',
                      PostalCode: '407',
                      County: '台中市',
                      District: '西屯區',
                      Address: '台中市西屯區台灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-23582999',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 168,
                      store_name: '77701台北總部',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address: '台北巿內湖區港墘路189號2樓',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 260,
                      store_name: '77705躍獅倉',
                      PostalCode: '333',
                      County: '桃園市',
                      District: '龜山區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 34,
                  area_sub_name: '總倉',
                  store: [
                    {
                      store_id: 215,
                      store_name: '66901彰濱退貨倉',
                      PostalCode: '435',
                      County: '台中市',
                      District: '梧棲區',
                      Address: '台中市西屯區台灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-23582999',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 228,
                      store_name: '66601彰濱理貨倉',
                      PostalCode: '435',
                      County: '台中市',
                      District: '梧棲區',
                      Address: '台中市西屯區台灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-23582999',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 233,
                      store_name: '55901富民退貨倉',
                      PostalCode: '435',
                      County: '台中市',
                      District: '梧棲區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 234,
                      store_name: '55601富民理貨倉',
                      PostalCode: '435',
                      County: '台中市',
                      District: '梧棲區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 235,
                      store_name: '55701富民贈品倉',
                      PostalCode: '100',
                      County: '台北市',
                      District: '中正區',
                      Address: '北堤路2號(富民)',
                      Section: '北部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 244,
                      store_name: '55902富民運損倉',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 245,
                      store_name: '55999富民調整倉',
                      PostalCode: '436',
                      County: '台中市',
                      District: '清水區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 250,
                      store_name: '55904富民報廢倉',
                      PostalCode: '435',
                      County: '台中市',
                      District: '梧棲區',
                      Address: '臺中市西屯區臺灣大道四段925號20F-1',
                      Section: '中部',
                      Tel: '04-37062587',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 9,
              area_name: '網路',
              area_sub: [
                {
                  area_sub_id: 32,
                  area_sub_name: '網站購物',
                  store: [
                    {
                      store_id: 112,
                      store_name: '9001網購門市',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address: '臺中市西屯區臺灣大道4段925號20樓之1',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 261,
                      store_name: '9003札幌網購門市',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address: '臺中市西屯區臺灣大道4段925號20樓之1',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 264,
                      store_name: '9004預先出庫',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address: '臺中市西屯區臺灣大道4段925號20樓之1',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 33,
                  area_sub_name: 'APP購物',
                  store: [
                    {
                      store_id: 224,
                      store_name: '9002APP門市',
                      PostalCode: null,
                      County: '',
                      District: '',
                      Address: '',
                      Section: '',
                      Tel: '0',
                      Lat: 0,
                      Lng: 0,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 11,
              area_name: '台北區',
              area_sub: [
                {
                  area_sub_id: 19,
                  area_sub_name: '台北一區',
                  store: [
                    {
                      store_id: 141,
                      store_name: 'A1113文山木新店',
                      PostalCode: '116',
                      County: '台北市',
                      District: '文山區',
                      Address: '台北市文山區木新路3段203號1F',
                      Section: '北部',
                      Tel: '02-86611465',
                      Lat: 24.981728,
                      Lng: 121.560479,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 142,
                      store_name: 'A1114文山興隆店',
                      PostalCode: '116',
                      County: '台北市',
                      District: '文山區',
                      Address: '台北市文山區興隆路3段106號',
                      Section: '北部',
                      Tel: '02-29347813',
                      Lat: 24.99934,
                      Lng: 121.557262,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 146,
                      store_name: 'A1118信義吳興店',
                      PostalCode: '110',
                      County: '台北市',
                      District: '信義區',
                      Address: '台北市信義區吳興街255號1F',
                      Section: '北部',
                      Tel: '02-27583945',
                      Lat: 25.027116,
                      Lng: 121.563671,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 20,
                  area_sub_name: '台北二區',
                  store: [
                    {
                      store_id: 111,
                      store_name: '1101瑞光藥局',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address: '台北市內湖區瑞光路212號1F',
                      Section: '北部',
                      Tel: '02-87513365',
                      Lat: 25.075333,
                      Lng: 121.576219,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 115,
                      store_name: '1302佑全內湖康寧藥局',
                      PostalCode: '114',
                      County: '台北市',
                      District: '內湖區',
                      Address:
                        '臺北市內湖區金湖里康寧路3段39號1-2樓.41號1樓.43號1樓',
                      Section: '北部',
                      Tel: '02-26346578',
                      Lat: 25.072583,
                      Lng: 121.607798,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 134,
                      store_name: 'A1106大安健康人生藥局',
                      PostalCode: '106',
                      County: '台北市',
                      District: '大安區',
                      Address: '台北市大安區信義路4段34號',
                      Section: '北部',
                      Tel: '02-27025553',
                      Lat: 25.033193,
                      Lng: 121.544752,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 137,
                      store_name: 'A1109中山龍江二店',
                      PostalCode: '104',
                      County: '台北市',
                      District: '中山區',
                      Address: '台北市中山區龍江路356巷14號',
                      Section: '北部',
                      Tel: '02-25023724',
                      Lat: 25.065962,
                      Lng: 121.540282,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 144,
                      store_name: 'A1116佑全松山南京東藥局',
                      PostalCode: '105',
                      County: '台北市',
                      District: '松山區',
                      Address: '臺北市松山區安平里南京東路5段291巷7號',
                      Section: '北部',
                      Tel: '02-27687807',
                      Lat: 25.051998,
                      Lng: 121.566213,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~21:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 21,
                  area_sub_name: '台北三區',
                  store: [
                    {
                      store_id: 135,
                      store_name: 'A1107大安羅斯福店',
                      PostalCode: '106',
                      County: '台北市',
                      District: '大安區',
                      Address: '台北市大安區羅斯福路2段83號',
                      Section: '北部',
                      Tel: '02-23660895',
                      Lat: 25.025598,
                      Lng: 121.523673,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 139,
                      store_name: 'A1111中正汀州店',
                      PostalCode: '100',
                      County: '台北市',
                      District: '中正區',
                      Address: '台北市中正區汀州路3段211號',
                      Section: '北部',
                      Tel: '02-23688902',
                      Lat: 25.014169,
                      Lng: 121.533625,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '10:00~22:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 140,
                      store_name: 'A1112中正南昌店',
                      PostalCode: '100',
                      County: '台北市',
                      District: '中正區',
                      Address: '台北市中正區南昌路1段153號',
                      Section: '北部',
                      Tel: '02-23969382',
                      Lat: 25.02929,
                      Lng: 121.518694,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~21:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 143,
                      store_name: 'A1115石牌健康人生藥局',
                      PostalCode: '112',
                      County: '台北市',
                      District: '北投區',
                      Address: '台北市北投區石牌路2段187號',
                      Section: '北部',
                      Tel: '02-28225169',
                      Lat: 25.118604,
                      Lng: 121.518311,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~21:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 266,
                      store_name: '1110佑全大安敦南藥局',
                      PostalCode: '106',
                      County: '台北市',
                      District: '大安區',
                      Address: '臺北市大安區義安里敦化南路二段63巷46號',
                      Section: '北部',
                      Tel: '02-23257579',
                      Lat: 25.0309147,
                      Lng: 121.5489967,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 12,
              area_name: '新北宜區',
              area_sub: [
                {
                  area_sub_id: 18,
                  area_sub_name: '宜蘭羅東區',
                  store: [
                    {
                      store_id: 120,
                      store_name: '1601羅東公正店',
                      PostalCode: '265',
                      County: '宜蘭縣',
                      District: '羅東鎮',
                      Address: '宜蘭縣羅東鎮公正路220號',
                      Section: '東部',
                      Tel: '039-552504',
                      Lat: 24.67936,
                      Lng: 121.767281,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 170,
                      store_name: '1602羅東興東南店',
                      PostalCode: '265',
                      County: '宜蘭縣',
                      District: '羅東鎮',
                      Address: '宜蘭縣羅東鎮興東南路75號',
                      Section: '東部',
                      Tel: '03-9568801',
                      Lat: 24.669838,
                      Lng: 121.769907,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 219,
                      store_name: '1605宜蘭和睦店',
                      PostalCode: '260',
                      County: '宜蘭縣',
                      District: '宜蘭市',
                      Address: '宜蘭縣宜蘭市和睦路47號1.2F',
                      Section: '東部',
                      Tel: '039-313622',
                      Lat: 24.7560934,
                      Lng: 121.7570583,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 252,
                      store_name: '1606佑全蘇澳中山藥局',
                      PostalCode: '270',
                      County: '宜蘭縣',
                      District: '蘇澳鎮',
                      Address: '宜蘭縣蘇澳鎮蘇西里中山路一段231號1樓',
                      Section: '東部',
                      Tel: '03-9961063',
                      Lat: 24.5956017,
                      Lng: 121.8423944,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 22,
                  area_sub_name: '新北一區',
                  store: [
                    {
                      store_id: 242,
                      store_name: '1312佑全板橋四川藥局',
                      PostalCode: '220',
                      County: '新北市',
                      District: '板橋區',
                      Address: '新北市板橋區四川路一段471號1樓',
                      Section: '北部',
                      Tel: '02-29559598',
                      Lat: 24.997395,
                      Lng: 121.4551043,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '週一~週五09:00~21:00、週六、週日公休',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 243,
                      store_name: '1313芳碩藥局',
                      PostalCode: '235',
                      County: '新北市',
                      District: '中和區',
                      Address: '新北市中和區連城路47巷2號1樓',
                      Section: '北部',
                      Tel: '02-82458816',
                      Lat: 25.001526,
                      Lng: 121.4959783,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '週一~週六08:30~22:00、週日08:30~12:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 246,
                      store_name: '1314中和環球藥局',
                      PostalCode: '235',
                      County: '新北市',
                      District: '中和區',
                      Address: '新北市中和區民有里中山路3段121號1樓',
                      Section: '北部',
                      Tel: '02-22211812',
                      Lat: 25.0068405,
                      Lng: 121.4732275,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '週一~週六8:00~21:30、週日公休',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 249,
                      store_name: '1315康橋藥局',
                      PostalCode: '235',
                      County: '新北市',
                      District: '中和區',
                      Address: '新北市板橋區公館里文化路一段273號',
                      Section: '北部',
                      Tel: '02-22532560',
                      Lat: 25.018481,
                      Lng: 121.4624573,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '週一~週五09:00~22:00、週六08:00~15:00、週日公休',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 262,
                      store_name: '1318佑全中和景新藥局',
                      PostalCode: '235',
                      County: '新北市',
                      District: '中和區',
                      Address: '新北巿中和區景新街395號1樓',
                      Section: '北部',
                      Tel: '02-29431408',
                      Lat: 24.989947,
                      Lng: 121.5103871,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 23,
                  area_sub_name: '新北二區',
                  store: [
                    {
                      store_id: 148,
                      store_name: 'A1302永正藥局',
                      PostalCode: '234',
                      County: '新北市',
                      District: '永和區',
                      Address:
                        '新北市永和區中正路191號、193號1F、197號2F、197號2F-1',
                      Section: '北部',
                      Tel: '02-29467351',
                      Lat: 24.998934,
                      Lng: 121.517017,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 155,
                      store_name: 'A1309新店民族店',
                      PostalCode: '231',
                      County: '新北市',
                      District: '新店區',
                      Address: '新北市新店區民族路186號1F',
                      Section: '北部',
                      Tel: '02-22194743',
                      Lat: 24.976162,
                      Lng: 121.536937,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~21:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 240,
                      store_name: '1310康和藥局',
                      PostalCode: '234',
                      County: '新北市',
                      District: '永和區',
                      Address: '新北市永和區安和里中和路589號1樓',
                      Section: '北部',
                      Tel: '02-22310300',
                      Lat: 25.004003,
                      Lng: 121.51215,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 256,
                      store_name: '1316佑全永和中山藥局',
                      PostalCode: '234',
                      County: '新北市',
                      District: '永和區',
                      Address: '新北市永和區中山路一段227、229、231號',
                      Section: '北部',
                      Tel: '02-22331546',
                      Lat: 25.0076527,
                      Lng: 121.5059253,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 25,
                  area_sub_name: '新北三區',
                  store: [
                    {
                      store_id: 149,
                      store_name: 'A1303汐止忠孝東店',
                      PostalCode: '221',
                      County: '新北市',
                      District: '汐止區',
                      Address: '新北市汐止區忠孝東路269號',
                      Section: '北部',
                      Tel: '02-86421820',
                      Lat: 25.068028,
                      Lng: 121.663403,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 154,
                      store_name: 'A1308淡水清水店',
                      PostalCode: '251',
                      County: '新北市',
                      District: '淡水區',
                      Address: '新北市淡水區清水街9-2號1F',
                      Section: '北部',
                      Tel: '02-26269679',
                      Lat: 25.170334,
                      Lng: 121.444766,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '08:30~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 236,
                      store_name: '1309佑全淡水淡金藥局',
                      PostalCode: '251',
                      County: '新北市',
                      District: '淡水區',
                      Address: '新北市淡水區淡金路55、57號樓',
                      Section: '北部',
                      Tel: '02-26228040',
                      Lat: 25.161323,
                      Lng: 121.456209,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 38,
                  area_sub_name: '新北四區',
                  store: [
                    {
                      store_id: 152,
                      store_name: 'A1306板橋莊敬店',
                      PostalCode: '220',
                      County: '新北市',
                      District: '板橋區',
                      Address: '新北市板橋區莊敬路87號',
                      Section: '北部',
                      Tel: '02-22570028',
                      Lat: 25.026463,
                      Lng: 121.472764,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '10:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 158,
                      store_name: 'A1312樹林博愛店',
                      PostalCode: '238',
                      County: '新北市',
                      District: '樹林區',
                      Address: '新北市樹林區博愛一街26號',
                      Section: '北部',
                      Tel: '02-26814394',
                      Lat: 24.990979,
                      Lng: 121.425553,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 225,
                      store_name: '1308佑全三重正義藥局',
                      PostalCode: '241',
                      County: '新北市',
                      District: '三重區',
                      Address: '新北市三重區正義北路207號1-2樓.209號1-2樓',
                      Section: '北部',
                      Tel: '02-29815341',
                      Lat: 25.0684767,
                      Lng: 121.4960953,
                      HasParking: false,
                      HasHealthInsurancen: true,
                      time: '09:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
            {
              area_id: 13,
              area_name: '札幌區',
              area_sub: [
                {
                  area_sub_id: 36,
                  area_sub_name: '札幌一區',
                  store: [
                    {
                      store_id: 231,
                      store_name: '1108札幌萬華店',
                      PostalCode: '108',
                      County: '台北市',
                      District: '萬華區',
                      Address: '台北市萬華區西門里成都路60-1號1-6樓',
                      Section: '北部',
                      Tel: '02-23122072',
                      Lat: 25.042761,
                      Lng: 121.505549,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '10:00~23:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 253,
                      store_name: '1109札幌南港店',
                      PostalCode: '115',
                      County: '台北市',
                      District: '南港區',
                      Address: '台北市忠孝東路七段359號B棟2樓B1702+B1802櫃',
                      Section: '北部',
                      Tel: '02-27862033',
                      Lat: 25.0522231,
                      Lng: 121.6064101,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '週日~週四11:00~21:30、週五~週六11:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 257,
                      store_name: '1201札幌基隆店',
                      PostalCode: '200',
                      County: '基隆市',
                      District: '仁愛區',
                      Address: '基隆市仁愛區孝二路86號1樓',
                      Section: '北部',
                      Tel: '02-24284282',
                      Lat: 25.1301524,
                      Lng: 121.7384299,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '11:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 259,
                      store_name: '1317札幌新莊店',
                      PostalCode: '242',
                      County: '新北市',
                      District: '新莊區',
                      Address: '.',
                      Section: '北部',
                      Tel: '02-85213840',
                      Lat: 25.059893,
                      Lng: 121.4487524,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '週日~週四11:00~21:30、週五~週六11:00~22:00',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 263,
                      store_name: '1319札幌汐止店',
                      PostalCode: '221',
                      County: '新北市',
                      District: '汐止區',
                      Address: '新北市汐止區',
                      Section: '北部',
                      Tel: '02-26595559',
                      Lat: 25.0620905,
                      Lng: 121.6456412,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 267,
                      store_name: '2707札幌嘉義店',
                      PostalCode: '600',
                      County: '嘉義市',
                      District: '嘉義市',
                      Address: '嘉義市',
                      Section: '南部',
                      Tel: '05-2842951',
                      Lat: 23.4723259,
                      Lng: 120.4425977,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                    {
                      store_id: 269,
                      store_name: '3307札幌義享店',
                      PostalCode: '804',
                      County: '高雄市',
                      District: '鼓山區',
                      Address: '高雄市鼓山區大順一路115號AB1-01+02',
                      Section: '南部',
                      Tel: '07-5507049',
                      Lat: 22.6568176,
                      Lng: 120.3044428,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
                {
                  area_sub_id: 39,
                  area_sub_name: '札幌二區',
                  store: [
                    {
                      store_id: 254,
                      store_name: '2325札幌麗寶店',
                      PostalCode: '421',
                      County: '台中市',
                      District: '后里區',
                      Address:
                        '台中市后里區福容路201號麗寶OutletMall3樓B區-3102',
                      Section: '中部',
                      Tel: '04-25583390',
                      Lat: 24.3244822,
                      Lng: 120.6997938,
                      HasParking: false,
                      HasHealthInsurancen: false,
                      time: '週一~週四11:00~21:30、週五11:00~22:00、週六10:30~22:00、週日10:30~21:30',
                      PickUp: false,
                      AppMemo: '',
                    },
                  ],
                },
              ],
            },
          ],
          message: '成功',
        },
      }
    },
  },
]
