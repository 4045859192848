<template>
  <div class="dashboard-container">
    <a-page-header title="健康服務首頁">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <a-row :gutter="8">
      <a-col :xs="24" :sm="4" :md="2" :lg="2" :xl="2" style="margin: auto">
        <label>查詢範圍:</label>
      </a-col>
      <a-col :xs="24" :sm="18" :md="20" :lg="20" :xl="20">
        <storeSelector @get-value="handleGetStoreValue" :userView="true" />
      </a-col>
      <a-col span="2">
        <a-button type="primary" @click="handleSearch">查詢</a-button>
      </a-col>
    </a-row>
    <a-radio-group
      v-model:value="period"
      button-style="solid"
      style="margin-top: 10px"
    >
      <a-radio-button value="1">前1日</a-radio-button>
      <a-radio-button value="7">前7日</a-radio-button>
      <a-radio-button value="30">前30日</a-radio-button>
    </a-radio-group>
    <span>(非即時)</span>

    <div class="sub-title">健康紀錄</div>
    <a-row :gutter="[16, 16]" style="margin-top: 12px">
      <a-col :span="24">
        <a-card style="min-height: 400px">
          <!-- <div class="card-title">當期健康記錄數</div> -->
          <div
            class="cart-content-chart"
            ref="container"
            style="min-height: 400px; margin: 0 -20px"
          ></div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">
            <CheckCircleOutlined />
            單日量測人數
          </div>
          <div class="card-content">
            <p>80</p>
          </div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">
            <BarChartOutlined />
            單日量測記錄數
          </div>
          <div class="card-content">
            <p>110</p>
          </div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">
            <InfoCircleOutlined />
            單日異常記錄
          </div>
          <div class="card-content">
            <p>80</p>
          </div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">本月量測人述及服務分布</div>
          <div class="cart-content-chart" id="chart-bar"></div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">量測項目占比</div>
          <div class="cart-content-chart" id="measurement-item-pie"></div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <a-card>
          <div class="card-title">服務方案占比</div>
          <div class="cart-content-chart" id="service-plan-pie"></div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  // https://github.com/motea927/Example-ECharts-Vue3
  import { onMounted, ref } from 'vue'
  import {
    QuestionCircleOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    BarChartOutlined,
  } from '@ant-design/icons-vue'
  import storeSelector from '@/components/StoreSelector.vue'
  export default {
    name: 'Dashboard',
    components: {
      QuestionCircleOutlined,
      CheckCircleOutlined,
      InfoCircleOutlined,
      BarChartOutlined,
      storeSelector,
    },
    data() {
      return {}
    },
    setup() {
      let filterStore = {}
      const period = ref('1')
      const container = ref(null)
      const handleGetStoreValue = (value) => {
        filterStore = value
      }
      const handleSearch = () => {
        console.log(filterStore)
        console.log(period)
      }
      onMounted(() => {})

      return {
        filterStore,
        period,
        handleGetStoreValue,
        handleSearch,
        container,
      }
    },
    created() {
      window.addEventListener('resize', this.onScreenResize)
    },
    mounted() {},
    methods: {},
  }
</script>
<style lang="less">
  .dashboard-container {
    margin-top: 0;
    height: 100vh;
    background-size: cover;
    .sub-title {
      margin-top: 16px;
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      border-bottom: 1px solid #000000;
      width: 200px;
    }
    .ant-card {
      border-radius: 10px;
      text-align: center;
      min-height: 200px;
      position: relative;
      align-items: center;
      @title-font-size: 26px;
      .card-title {
        font-weight: 500;
        font-size: @title-font-size;
        line-height: @title-font-size;
        letter-spacing: 0.2px;
        vertical-align: middle;
      }
      .card-content {
        display: inline-block;
        position: absolute;
        top: calc((100% - @title-font-size - 24px) / 2);
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        > p {
          font-size: 36px;
          font-weight: 300;
        }
      }
      .cart-content-chart {
        // display: inline-block;
        // position: absolute;
        // top: 0px;
        // right: 0;
        // bottom: 0;
        // left: 0;
        //margin: auto;
        // width: 100%;
        // min-height: 200px;
      }
    }
  }
</style>
