module.exports = [
  // 取得所有常用搜尋
  {
    url: '/searchRules',
    type: 'get',
    response() {
      return {
        result: [
          {
            id: 1,
            title: 'CDC：過輕',
            staffId: '09777',
            type: 'weight',
            clauses: [
              {
                subject: 'bmi',
                predicate: 'lt',
                component: 18.5,
              },
            ],
          },
          {
            id: 2,
            title: 'WHO：第一級高血壓 (輕微)',
            staffId: '09777',
            type: 'bloodPressure',
            clauses: [
              {
                subject: 'systolic',
                predicate: 'bw',
                component: [140, 159],
              },
              {
                sets: 'or',
                subject: 'diastolic',
                predicate: 'bw',
                component: [90, 99],
              },
            ],
          },
          {
            id: 3,
            title: 'WHO：第三級高血壓 (嚴重)',
            staffId: '09777',
            type: 'bloodPressure',
            clauses: [
              {
                subject: 'systolic',
                predicate: 'ge',
                component: 180,
              },
              {
                sets: 'or',
                subject: 'diastolic',
                predicate: 'ge',
                component: 110,
              },
            ],
          },
        ],
      }
    },
  },

  // 刪除指定的常用搜尋
  {
    url: '/searchRules/\\d*',
    type: 'delete',
    response() {
      return {
        result: 'success',
      }
    },
  },
  // 修改指定的常用搜尋
  {
    url: '/searchRules',
    type: 'patch',
    response() {
      return {
        code: 200,
        msg: 'success',
        data: {},
      }
    },
  },
  // 新建常用搜尋
  {
    url: '/searchRules',
    type: 'post',
    response() {
      return {
        code: 200,
        msg: 'success',
        data: {},
      }
    },
  },
  // 取得基本搜尋(棄用)
  {
    url: '/getBasicSettingDetail',
    type: 'get',
    response() {
      const data = {
        key: 1,
        name: '北區、女性大於10歲',
        rules: [
          {
            key: 'name',
            name: '姓名',
            valueType: 'string',
            checked: false,
            operator: 'And',
            operatorItem: '=',
          },
          {
            key: 'age',
            name: '年齡',
            valueType: 'number',
            checked: true,
            operator: 'And',
            operatorItem: '=',
            operantItem1: 10,
          },
          {
            key: 'gender',
            name: '性別',
            checked: true,
            operator: 'And',
            operatorItem: '=',
            operantItem1: 'w',
          },
          {
            key: 'cust_level',
            name: '會員等級',
            checked: true,
            operator: 'And',
            operatorItem: '=',
            operantItem1: '1',
          },
          {
            key: 'store',
            name: '量測門市',
            valueType: 'store',
            checked: false,
            operator: 'And',
            operatorItem: '=',
          },
        ],
        creator: '',
        created_time: '',
        editor: '',
        edit_time: '',
        type: 'Basic',
        id: '1',
      }
      return {
        code: 200,
        msg: 'success',
        data,
      }
    },
  },
  // 取得進階搜尋(棄用)
  {
    url: '/getSettingDetail',
    type: 'get',
    response() {
      const data = {
        name: '北區、女性大於10歲',
        rules: [
          {
            item: 'age',
            operantItem1: '10',
            operantItem2: '',
            operantItem3: '',
            operator: '',
            operatorItem: '>=',
            type: 'Basic',
          },
          {
            item: 'store',
            operantItem1: 5,
            operantItem2: 7,
            operantItem3: '',
            operator: '',
            operatorItem: '=',
            type: 'Basic',
          },
          {
            item: 'gender',
            operantItem1: 'w',
            operantItem2: '',
            operantItem3: '',
            operator: '',
            operatorItem: '=',
            type: 'Basic',
          },
        ],
        creator: '',
        created_time: '',
        editor: '',
        edit_time: '',
      }
      return {
        code: 200,
        msg: 'success',
        data,
      }
    },
  },
]
