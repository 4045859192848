module.exports = [
  // 以會員編號、手機號碼、電話、姓名找會員資料
  {
    url: '/members',
    type: 'get',
    response(config) {
      const key = Object.keys(config.query)
      const $listKey = ['phone', 'name']
      if ($listKey.some((item) => item === key[0])) {
        return {
          found: true,
          message: '成功',
          members: [
            {
              memberId: 1655108,
              cardId: '1655108',
              levelName: '一般會員',
              name: '陳裕如',
              phoneNumber: '0933976303',
              gender: 'F',
              birthday: '0000-00-00',
            },
            {
              memberId: 1511262,
              cardId: '1511262',
              levelName: '一般會員',
              name: '陳裕如',
              phoneNumber: '0933976303',
              gender: 'F',
              birthday: '0000-00-00',
            },
          ],
        }
      } else {
        return {
          found: true,
          cardId: '0101373992',
          memberId: 1655108,
          levelName: '一般會員',
          name: '楊天福',
          phoneNumber: '0936084533',
          telNumber: '0936084533',
          gender: 'M',
          email: '123@gmail.com',
          birthday: '0000-00-00',
          addr: '112 臺北市北投區XX路XX號X樓',
          IDNum: 'A12345678',
        }
      }
      // return {
      //   code: 200,
      //   msg: 'success',
      //   data: [
      //     {
      //       key: 12345678,
      //       cust_id: 12345678,
      //       card_id: '0123456789',
      //       name: '王小明',
      //       cust_level: '白金會員',
      //       mobile: '0912789456',
      //       gender: '男',
      //       birth: '1912-12-21',
      //       created: false,
      //     },
      //     {
      //       key: 12345600,
      //       cust_id: 12345600,
      //       card_id: '0123456700',
      //       name: '王大明',
      //       cust_level: '一般會員',
      //       mobile: '0912789456',
      //       gender: '男',
      //       birth: '1912-12-21',
      //       created: true,
      //     },
      //     {
      //       key: 78945612,
      //       cust_id: 78945612,
      //       card_id: '0125836974',
      //       name: '陳小東',
      //       cust_level: '一般會員',
      //       mobile: '0912789456',
      //       gender: '女',
      //       birth: '1985-01-05',
      //       created: true,
      //       record: true,
      //     },
      //   ],
      // }
    },
  },
  {
    url: '/getCustInfo',
    type: 'post',
    response() {
      return {
        code: 200,
        msg: 'success',
        data: {
          cust_id: 12345678,
          card_id: '0123456789',
          plan_now: '體驗方案',
          plan_data_end: '2021-09-04',
          name: '王小明',
          cust_level: '白金會員',
          mobile: '0912789456',
          gender: '男',
          birth: '1912-12-21',
          created: false,
        },
      }
    },
  },
]
